import { createStoreContext } from '@vklink/libs-state';
import { InternalUserStoreInstance } from './InternalUserStore';

const [InternalUserStoreProvider, useInternalUserStore] =
  createStoreContext<InternalUserStoreInstance>();

export { InternalUserStoreProvider, useInternalUserStore };

export {
  default as InternalUserStore,
  type InternalUserStoreEnv,
  type InternalUserStoreInstance,
} from './InternalUserStore';
