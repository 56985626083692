import { OrderStatus } from 'enums';

export const OrderStatusOptions: SelectOption[] = [
  {
    label: 'Waiting Confirmation',
    value: OrderStatus.WAITING,
  },
  {
    label: 'Confirmed',
    value: OrderStatus.CONFIRMED,
  },
  {
    label: 'Packed',
    value: OrderStatus.PACKED,
  },
  {
    label: 'Shipping',
    value: OrderStatus.SHIPPING,
  },
  {
    label: 'Delivered',
    value: OrderStatus.DELIVERED,
  },

  {
    label: 'Received',
    value: OrderStatus.RECEIVED,
  },
  {
    label: 'Cancelled',
    value: OrderStatus.CANCEL,
  },
];

export const OrderStatusWithoutCancelledOptions: SelectOption[] = OrderStatusOptions.filter(
  (el) => el.value !== OrderStatus.CANCEL
);

export const OrderStatusCancelledOptions: SelectOption[] = [
  {
    label: 'Waiting Confirmation',
    value: OrderStatus.WAITING,
  },
  {
    label: 'Cancelled',
    value: OrderStatus.CANCEL,
  },
];
