import { SnapshotOut, types } from 'mobx-state-tree';

export const PaginationParamsModel = types.model({
  pageSize: types.optional(types.number, 10),
  pageNumber: types.optional(types.number, 1),
});

export const DefaultPaginationParams: PaginationParams = {
  pageSize: 10,
  pageNumber: 1,
};

export interface PaginationParams extends SnapshotOut<typeof PaginationParamsModel> {}
