import { createStoreContext } from '@vklink/libs-state';
import { type DashboardStoreInstance } from './DashboardStore';

const [DashboardStoreProvider, useDashboardStore] = createStoreContext<DashboardStoreInstance>();

export { DashboardStoreProvider, useDashboardStore };

export {
  default as DashboardStore,
  type DashboardStoreEnv,
  type DashboardStoreInstance,
} from './DashboardStore';
