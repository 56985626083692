import { types, SnapshotOut, Instance, SnapshotIn } from '@vklink/libs-state';
import { MediaIndex } from 'enums';

export const UploadFileModel = types.model('Upload File Model', {
  bucket: types.maybeNull(types.string),
  bucketName: types.maybeNull(types.string),
  path: types.string,
  extension: types.string,
  name: types.maybeNull(types.string),
  fileId: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  mediaIndex: types.maybeNull(types.enumeration<MediaIndex>(Object.values(MediaIndex))),
});

export const UploadMultipleFileModel = types.compose(
  UploadFileModel,
  types.model('Upload Multiple File Model', {
    mediaIndex: types.maybeNull(types.number),
    fileName: types.maybeNull(types.string),
  })
);

export interface UploadFileInstance extends Instance<typeof UploadFileModel> {}
export interface UploadFileInputModel extends SnapshotIn<typeof UploadFileModel> {}
export interface UploadFile extends SnapshotOut<typeof UploadFileModel> {}
