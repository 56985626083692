import { useMemo } from 'react';
import { Toolbar, IconButton, Box, Divider, Button, Stack } from '@mui/material';
import {
  AccessTimeOutlined,
  GroupOutlined,
  ChevronLeft,
  ChevronRight,
  FeedOutlined,
  Inventory2Outlined,
  MoreHorizOutlined,
  CategoryOutlined,
  SettingsOutlined,
  LogoutOutlined,
  ShoppingCartOutlined,
  PaidOutlined,
  // ManageAccountsOutlined,
  RuleOutlined,
} from '@mui/icons-material';
import { observer } from '@vklink/libs-state';
import {
  MenuItem,
  MenuItemType,
  MultipleLevelsMenu,
  CollapsibleDrawer,
} from '@vklink/components-shared';
import {
  BrandRoutes,
  CategoryRoutes,
  CustomerRoutes,
  OrderRoutes,
  ProductRoutes,
  SiteSettingRoutes,
  BannerSliderRoutes,
  PromotionRoutes,
  // InternalUserRoutes,
  RoleRoutes,
} from '../models';
import { CouponRoutes } from '../models/routes/Coupon';
import { useAuthStore } from '@vklink/libs-auth';

const Sidebar = () => {
  const menuItems = useMemo<Array<MenuItem>>(() => {
    return [
      {
        id: 'dashboardGroup',
        label: 'GENERAL',
        type: MenuItemType.Label,
      },
      {
        id: 'dashboard',
        label: 'Dashboard',
        type: MenuItemType.Link,
        href: '/',
        icon: <AccessTimeOutlined />,
      },
      {
        id: 'categoryManagement',
        label: 'Category Management',
        type: MenuItemType.Link,
        href: CategoryRoutes.LIST,
        icon: <CategoryOutlined />,
      },
      {
        id: 'brandManagement',
        label: 'Brand Management',
        type: MenuItemType.Link,
        icon: <MoreHorizOutlined />,
        href: BrandRoutes.LIST,
      },
      {
        id: 'productManagement',
        label: 'Product Management',
        type: MenuItemType.Link,
        icon: <ShoppingCartOutlined />,
        href: ProductRoutes.LIST,
      },
      {
        id: 'orderManagement',
        label: 'Order Management',
        href: OrderRoutes.LIST,
        type: MenuItemType.Link,
        icon: <FeedOutlined />,
      },
      {
        id: 'customerManagement',
        label: 'Customer Management',
        type: MenuItemType.Link,
        icon: <GroupOutlined />,
        href: CustomerRoutes.LIST,
      },
      {
        id: 'siteSetting',
        label: 'Site Setting',
        type: MenuItemType.Link,
        icon: <SettingsOutlined />,
        childs: [
          {
            id: 'generalSetting',
            label: 'General',
            href: SiteSettingRoutes.GENERAL,
            type: MenuItemType.Link,
          },
          {
            id: 'footerSetting',
            label: 'Footer',
            href: SiteSettingRoutes.FOOTER,
            type: MenuItemType.Link,
          },
          {
            id: 'bannerSliderSetting',
            label: 'Banner Slider',
            href: BannerSliderRoutes.LIST,
            type: MenuItemType.Link,
          },
          {
            id: 'socialLinksSetting',
            label: 'Social Links',
            href: SiteSettingRoutes.SOCIAL_LINKS,
            type: MenuItemType.Link,
          },
          {
            id: 'shippingVatSetting',
            label: 'Shipping & Vat',
            href: SiteSettingRoutes.SHIPPING_VAT,
            type: MenuItemType.Link,
          },
        ],
      },
      {
        id: 'couponsMangement',
        label: 'Coupons Mangement',
        type: MenuItemType.Link,
        href: CouponRoutes.LIST,
        icon: <Inventory2Outlined />,
      },
      {
        id: 'promotionMangement',
        label: 'Promotion Mangement',
        type: MenuItemType.Link,
        href: PromotionRoutes.LIST,
        icon: <PaidOutlined />,
      },
      {
        id: 'setting',
        label: 'Setting',
        type: MenuItemType.Link,
        icon: <SettingsOutlined />,
        childs: [
          // {
          //   id: 'userManagement',
          //   label: 'User Management',
          //   href: InternalUserRoutes.LIST,
          //   type: MenuItemType.Link,
          //   icon: <ManageAccountsOutlined />,
          // },
          {
            id: 'roleManagement',
            label: 'Role Management',
            href: RoleRoutes.LIST,
            type: MenuItemType.Link,
            icon: <RuleOutlined />,
          },
        ],
      },
    ];
  }, []); //re-render when change local state

  const authStore = useAuthStore();

  const handleLogout = () => {
    authStore.signoutAsync();
  };

  return (
    <CollapsibleDrawer width={380}>
      {({ isOpen, onToggleClick, isExpand }) => {
        return (
          <>
            <Toolbar />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                backgroundColor: (theme) => theme.palette.primary.main,
              }}
            >
              <IconButton
                sx={{
                  position: isOpen || isExpand ? 'absolute' : 'unset',
                  top: `${64 + 16 - (40 - 32) / 2}px`,
                  right: 25,
                  zIndex: 1,
                  w: 40,
                  h: 40,
                  alignSelf: 'center',
                  mb: isOpen || isExpand ? 0 : 1,
                  mt: !(isOpen || isExpand) ? 1 : 0,
                }}
                onClick={onToggleClick}
              >
                {!isOpen ? (
                  <ChevronRight
                    sx={{
                      color: (theme) => theme.palette.common.white,
                    }}
                  />
                ) : (
                  <ChevronLeft
                    sx={{
                      color: (theme) => theme.palette.common.white,
                    }}
                  />
                )}
              </IconButton>
              <MultipleLevelsMenu items={menuItems} isDrawerOpen={isOpen || isExpand} />
              <Divider sx={{ flexGrow: 1 }} />
              <Stack sx={{ py: 1 }}>
                <Button
                  size="large"
                  sx={{ color: 'common.white' }}
                  startIcon={<LogoutOutlined />}
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </Stack>
            </Box>
          </>
        );
      }}
    </CollapsibleDrawer>
  );
};

export default observer(Sidebar);
