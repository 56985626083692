import { PaymentMethods } from 'enums';

export const PaymentMethodOptions: SelectOption[] = [
  {
    label: 'Cash On Delivery',
    value: PaymentMethods.CASH_ON_DELIVERY,
  },
  {
    label: 'Bank Transfer',
    value: PaymentMethods.BANK_TRANSFER,
  },
  {
    label: 'POINT',
    value: PaymentMethods.POINT,
  },
  {
    label: 'Card (VISA, MASTER, JCB)',
    value: PaymentMethods.CARD,
  },
  {
    label: 'MOMO Wallet',
    value: PaymentMethods.MOMO_WALLET,
  },
];
