import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { PromotionType } from 'enums';
import { PromotionDetailModel, DefaultPromotionDetailValue } from './PromotionDetailModel';

export const PromotionModel = types.model('Promotion Model', {
  id: types.identifier,
  name: types.string,
  promotionType: types.string,
  enabled: types.boolean,
  description: types.maybeNull(types.string),
  maxQuantity: types.maybeNull(types.number),
  quantityUsed: types.maybeNull(types.number),
  startDate: types.string,
  endDate: types.string,
  createdAt: types.string,
  createdById: types.string,
  createdBy: types.string,
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  updatedById: types.maybeNull(types.string),

  promotionDetails: types.array(PromotionDetailModel),
});

export const DefaultPromotionValue: CreatePromotion = {
  name: '',
  description: '',
  maxQuantity: null,
  promotionType: PromotionType.GIFT,
  startDate: '',
  endDate: '',
  promotionDetails: [DefaultPromotionDetailValue],
};

export type CreatePromotion = Pick<
  PromotionInputModel,
  | 'name'
  | 'promotionType'
  | 'description'
  | 'maxQuantity'
  | 'endDate'
  | 'startDate'
  | 'promotionDetails'
>;

export interface PromotionInstance extends Instance<typeof PromotionModel> {}
export interface PromotionInputModel extends SnapshotIn<typeof PromotionModel> {}
export interface Promotion extends SnapshotOut<typeof PromotionModel> {}
