import { BannerType } from 'enums';

export const BannerTypeOptions: SelectOption[] = [
  {
    label: 'Event Banner',
    value: BannerType.EVENT_BANNER,
  },
  {
    label: 'Main Banner',
    value: BannerType.MAIN_BANNER,
  },
  {
    label: 'Sub Banner',
    value: BannerType.SUB_BANNER,
  },
];
