import { dayjs } from 'enums';

export const CURRENT_YEAR = dayjs().year();
export const BEFORE_YEAR = dayjs().subtract(1, 'year').year();
export const BEFORE_BEFORE_YEAR = dayjs().subtract(2, 'year').year();

export const ThreeYearsNearestOptions: SelectOption[] = [
  {
    label: CURRENT_YEAR.toString(),
    value: CURRENT_YEAR,
  },
  {
    label: BEFORE_YEAR.toString(),
    value: BEFORE_YEAR,
  },
  {
    label: BEFORE_BEFORE_YEAR.toString(),
    value: BEFORE_BEFORE_YEAR,
  },
];
