import { generatePath } from 'react-router';

import { types, Instance, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { AUTHENTICATION_API, CUSTOMER_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore } from 'stores';
import {
  Customer,
  CustomerFilterParams,
  CustomerFilterParamsModel,
  CustomerModel,
  DefaultCustomerFilterParams,
} from './models';

export type CustomerStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const CustomerStore = types
  .compose(
    PaginationStore,
    types.model('Customer Store', {
      filterParams: types.optional(CustomerFilterParamsModel, DefaultCustomerFilterParams),
      customers: types.array(CustomerModel),
      customerDetail: types.maybeNull(CustomerModel),
      passwordCustomer: types.optional(types.string, ''),
    })
  )
  .views((self) => {
    return {
      get listCustomers() {
        return getSnapshot(self.customers);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<CustomerStoreEnv>(self);

    const setQueryParams = (filterParams: CustomerFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultCustomerFilterParams, ...filterParams });
    };

    const setCustomerDetail = (customer: Customer) => {
      self.customerDetail = { ...customer };
    };

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status User');
      try {
        const url = generatePath(CUSTOMER_API.PUT_CUSTOMER_STATUS, {
          id: self.customerDetail?.id as string,
        });
        yield httpInstance.put(url, {
          status: !self.customerDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCustomersAsync = flow(function* () {
      const loadingId = load('Get Customer Async');

      try {
        const response = yield httpInstance.get(CUSTOMER_API.GET_CUSTOMERS, {
          params: self.getQueryParams,
        });

        applySnapshot(self.customers, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCustomerDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Customer Detail Async');
      try {
        const response = yield httpInstance.get(generatePath(CUSTOMER_API.GET_CUSTOMER, { id }));

        self.customerDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const resetPasswordCustomer = flow(function* (cb?: RequestCallback) {
      const loading = load('Reset Password Customer');

      try {
        const response = yield httpInstance.put(
          generatePath(AUTHENTICATION_API.PUT_CUSTOMER_PASSWORD, {
            id: self.customerDetail?.userId as string,
          })
        );
        self.passwordCustomer = response.data;

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loading);
      }
    });

    return {
      setQueryParams,
      setCustomerDetail,
      getCustomersAsync,
      getCustomerDetailByIdAsync,
      updateStatus,
      resetPasswordCustomer,
    };
  });

export default CustomerStore;

export type CustomerStoreInstance = Instance<typeof CustomerStore>;
