import { SnapshotIn, types } from '@vklink/libs-state';

export const InternalUserFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface InternalUserFilterParams
  extends SnapshotIn<typeof InternalUserFilterParamsModel> {}
export const DefaultInternalUserFilterParams: InternalUserFilterParams = {
  keyword: '',
};
