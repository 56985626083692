import { createStoreContext } from '@vklink/libs-state';
import { PromotionStoreInstance } from './PromotionStore';

const [PromotionStoreProvider, usePromotionStore] = createStoreContext<PromotionStoreInstance>();

export { PromotionStoreProvider, usePromotionStore };

export {
  default as PromotionStore,
  type PromotionStoreEnv,
  type PromotionStoreInstance,
} from './PromotionStore';
