import { ParentCategory } from 'enums';

export const ParentCategoryOptions: SelectOption[] = [
  {
    label: 'Accessories',
    value: ParentCategory.ACCESSORIES,
  },
  {
    label: 'Beauty',
    value: ParentCategory.BEAUTY,
  },
  {
    label: 'Clothes',
    value: ParentCategory.CLOTHES,
  },
  {
    label: 'Home',
    value: ParentCategory.HOME,
  },
  {
    label: 'Future Plan',
    value: ParentCategory.SHOES,
  },
];
