import { createStoreContext } from '@vklink/libs-state';
import { BannerSliderStoreInstance } from './BannerSliderStore';

const [BannerSliderStoreProvider, useBannerSliderStore] =
  createStoreContext<BannerSliderStoreInstance>();

export { BannerSliderStoreProvider, useBannerSliderStore };

export {
  default as BannerSliderStore,
  type BannerSliderStoreEnv,
  type BannerSliderStoreInstance,
} from './BannerSliderStore';
