import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ProductOptionsModel = types.model('Product Options Model', {
  id: types.identifier,
  code: types.string,
  name: types.string,
});

export interface ProductOptionsInstance extends Instance<typeof ProductOptionsModel> {}
export interface ProductOptionsInputModel extends SnapshotIn<typeof ProductOptionsModel> {}
export interface ProductOptions extends SnapshotOut<typeof ProductOptionsModel> {}
