import { SnapshotIn, types } from '@vklink/libs-state';

export const RoleFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface RoleFilterParams extends SnapshotIn<typeof RoleFilterParamsModel> {}
export const DefaultRoleFilterParams: RoleFilterParams = {
  keyword: '',
};
