import { SnapshotOut, types, Instance } from '@vklink/libs-state';

export const CustomerModel = types.model('Customer Model', {
  id: types.maybeNull(types.string),
  username: types.string,
  firstName: types.string,
  lastName: types.string,
  fullName: types.string,
  birthday: types.maybeNull(types.string),
  gender: types.string,
  point: types.number,
  phone: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  avatar: types.maybeNull(types.string),
  enabled: types.maybeNull(types.boolean),
  totalOrder: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  createdById: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  updatedById: types.maybeNull(types.string),
});

export interface Customer extends SnapshotOut<typeof CustomerModel> {}
export interface CustomerInstance extends Instance<typeof CustomerModel> {}
