import { Box } from '@mui/material';

const PageBody = (props: PageProps) => {
  return (
    <Box
      className="page-section page-body"
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

export default PageBody;
