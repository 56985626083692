import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { UploadFileModel, UploadInputModel } from 'pages/shared/models';
import { CategoryOptionsModel } from 'pages/shared/models/common-options';

export const BrandModel = types.model('Brand Model', {
  id: types.identifier,
  name: types.maybeNull(types.string),
  categoryIds: types.array(types.string),
  description: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  categories: types.array(CategoryOptionsModel),
  enabled: types.boolean,
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  imageUrl: types.maybeNull(types.string),

  images: types.array(UploadInputModel),
  media: types.maybeNull(UploadFileModel),

  imageBucket: types.maybeNull(types.string),
  imageFileExtention: types.maybeNull(types.string),
  imageFileName: types.maybeNull(types.string),
  imagePath: types.maybeNull(types.string),
  imageTitle: types.maybeNull(types.string),
});

export const DefaultBrandValue: Partial<BrandInputModel> = {
  name: '',
  description: '',
  categoryIds: [],
  images: [],
};

export type CreateBrand = Pick<
  BrandInputModel,
  'name' | 'categoryIds' | 'description' | 'images' | 'slug' | 'media'
>;

export interface BrandInstance extends Instance<typeof BrandModel> {}
export interface BrandInputModel extends SnapshotIn<typeof BrandModel> {}
export interface Brand extends SnapshotOut<typeof BrandModel> {}
