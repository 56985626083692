import { TrackingTime, TrackingTimeSku, TrackingTimeProfitMargin } from 'enums';

export const TrackingTimeOptions: SelectOption[] = [
  {
    label: 'Today',
    value: TrackingTime.TODAY,
  },
  {
    label: 'Monthly',
    value: TrackingTime.MONTHLY,
  },
  {
    label: 'Yearly',
    value: TrackingTime.YEARLY,
  },
];

export const TrackingTimeSkuOptions: SelectOption[] = [
  {
    label: 'Daily',
    value: TrackingTimeSku.DAILY,
  },
  {
    label: 'Weekly',
    value: TrackingTimeSku.WEEKLY,
  },
  {
    label: 'Monthly',
    value: TrackingTimeSku.MONTHLY,
  },
  {
    label: 'Yearly',
    value: TrackingTimeSku.YEARLY,
  },
];

export const TrackingTimeProfitMarginOptions: SelectOption[] = [
  {
    label: 'Monthly',
    value: TrackingTimeProfitMargin.MONTHLY,
  },
  {
    label: 'Yearly',
    value: TrackingTimeProfitMargin.YEARLY,
  },
];
