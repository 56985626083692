import { SnapshotIn, types } from '@vklink/libs-state';

export const BannerSliderFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  bannerType: types.optional(types.string, ''),
});

export interface BannerSliderFilterParams
  extends SnapshotIn<typeof BannerSliderFilterParamsModel> {}
export const DefaultBannerSliderFilterParams: BannerSliderFilterParams = {
  keyword: '',
  bannerType: '',
};
