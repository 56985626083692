import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { CategoryStore, CategoryStoreInstance, CategoryStoreProvider } from './stores';
import { PageLayout } from 'pages/shared/layout';

const CategoryLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<CategoryStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(CategoryStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);

  return (
    <CategoryStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </CategoryStoreProvider>
  );
};

export default CategoryLayout;
