import { createTheme } from '@mui/material/styles';
import { defaultTheme } from '@vklink/components-shared';

const theme = createTheme(defaultTheme, {
  palette: {
    mode: 'light',
    primary: {
      // main: '#2E4576',
      // main: '#003C8F',
      main: '#2E4576',
    },
    secondary: {
      main: '#B196DB',
    },
    success: {
      main: '#0AB39C',
    },
    info: {
      main: '#0288D1',
    },
    warning: {
      main: '#ED6C02',
    },
    error: {
      main: '#D32F2F',
    },
    dark: {
      main: '#222222',
    },
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
});

export default theme;
