import '@fontsource/roboto';

import { StrictMode, Suspense, useMemo, useEffect } from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { I18nProvider } from '@vklink/libs-i18n';
import { TopLoading, ErrorBoundary } from '@vklink/components-shared';

import i18n from 'i18n';

import theme from './themes';
import Routes from 'pages/shared/Routes';
import { initialRootState, RootStoreProvider, httpInstance } from 'stores';
import { ToastMessageProvider } from '@vklink/components-toast-message';
import { createAuthStore, AuthStoreProvider } from '@vklink/libs-auth';
import env from './env';
import { applyBearerTokenInterceptor } from '@vklink/libs-http';
import {
  applyCustomHeaderInterceptor,
  applyRefreshBearerTokenInterceptor,
  applyTransformResponseInterceptor,
  QueryClientProvider,
  createQueryClient,
} from '@vklink/libs-http';

const App = () => {
  const initAuthState = useMemo(
    () =>
      createAuthStore({
        authority: env.authConfig.authority,
        clientId: env.authConfig.clientId,
        clientSecret: env.authConfig.clientSecret,
        redirectUrl: env.authConfig.redirectUri,
        silentRedirectUrl: env.authConfig.silentRedirectUri,
        postLogoutUrl: env.authConfig.postLogoutRedirectUri,
        scopes: env.authConfig.scopes,
        storePrefix: env.authConfig.storePrefix,
      }),
    []
  );
  useEffect(() => {
    configServiceWithToken(httpInstance);
  }, [initAuthState]);

  useEffect(() => {
    if (initAuthState.isAuthenticated) {
      applyBearerTokenInterceptor(httpInstance, async () =>
        Promise.resolve(initAuthState.accessToken)
      );
    }
  }, [initAuthState.isAuthenticated]);

  const configServiceWithToken = (http: HttpInstance) => {
    configHeader(http);

    applyBearerTokenInterceptor(http, async () => Promise.resolve(initAuthState.accessToken));

    applyRefreshBearerTokenInterceptor(http, async () => {
      await initAuthState.signinSilentAsync();

      return Promise.resolve(initAuthState.accessToken);
    });
  };

  const configHeader = (http: HttpInstance) => {
    applyCustomHeaderInterceptor(http, async () =>
      Promise.resolve({
        'Content-Type': 'application/json',
        'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Accept-Language': Intl.DateTimeFormat().resolvedOptions().locale,
      })
    );

    applyTransformResponseInterceptor(http);
  };

  const queryClient = createQueryClient(httpInstance);

  return (
    <StrictMode>
      <AuthStoreProvider value={initAuthState}>
        <I18nProvider i18n={i18n}>
          <RootStoreProvider value={initialRootState}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={theme}>
                <ErrorBoundary>
                  <ToastMessageProvider>
                    <CssBaseline />
                    <Router>
                      <Suspense fallback={<TopLoading />}>
                        <Routes />
                      </Suspense>
                    </Router>
                  </ToastMessageProvider>
                </ErrorBoundary>
              </ThemeProvider>
            </QueryClientProvider>
          </RootStoreProvider>
        </I18nProvider>
      </AuthStoreProvider>
    </StrictMode>
  );
};

export default App;
