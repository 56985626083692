import { generatePath } from 'react-router';

import { types, Instance, getSnapshot, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { PERMISSION_API, ROLE_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore } from 'stores';
import { StableRole } from '../../../constant/src/role';
import {
  DefaultRoleFilterParams,
  RoleInstance,
  RoleFilterParams,
  RoleFilterParamsModel,
  RoleModel,
  PermissionModel,
  CreateRole,
} from './models';

export type RoleStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const RoleStore = types
  .compose(
    PaginationStore,
    types.model('Role Store', {
      filterParams: types.optional(RoleFilterParamsModel, DefaultRoleFilterParams),
      listRoles: types.array(RoleModel),
      roleDetail: types.maybeNull(RoleModel),
      permissions: types.array(PermissionModel),
    })
  )
  .views((self) => ({
    get getListRoles() {
      return getSnapshot(self.listRoles);
    },
    get getListPermissions() {
      return getSnapshot(self.permissions);
    },
    get getQueryParams() {
      return removeEmptyInObject({
        ...self.filterParams,
        ...self.paginationParams,
      });
    },
  }))
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<RoleStoreEnv>(self);

    const isDisableEdit = (name: string) => {
      console.log(name);

      return StableRole.includes(name);
    };

    const setQueryParams = (filterParams: RoleFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultRoleFilterParams, ...filterParams });
    };

    const setRoleDetail = (role: RoleInstance) => {
      self.roleDetail = { ...role };
    };

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Role');
      const url = generatePath(ROLE_API.PUT_ROLE_STATUS, { id: self.roleDetail?.id });

      try {
        yield httpInstance.put(url, {
          status: !self.roleDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getRolesAsync = flow(function* () {
      const loadingId = load('Get Internal Users Async');
      try {
        const response = yield httpInstance.get(ROLE_API.GET_ROLES, {
          params: self.getQueryParams,
        });
        applySnapshot(self.listRoles, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getPermissionsAsync = flow(function* () {
      const loadingId = load('Get Permissions Async');
      try {
        const response = yield httpInstance.get(PERMISSION_API.GET_PERMISSIONS);

        applySnapshot(self.permissions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getRoleDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Role Detail Async');
      try {
        const response = yield httpInstance.get(generatePath(ROLE_API.GET_ROLE, { id }));

        self.roleDetail = {
          ...response.data,
          permissions: response.data.roleClaims,
        };
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createRoleAsync = flow(function* (role: CreateRole, cb?: RequestCallback) {
      const loadingId = load('Create Role Async');
      try {
        yield httpInstance.post(ROLE_API.POST_ROLE, role);

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateRoleAsync = flow(function* (id: string, role: CreateRole, cb?: RequestCallback) {
      const loadingId = load('Update Role Async');
      try {
        const url = generatePath(ROLE_API.PUT_ROLE, { id });
        yield httpInstance.put(url, role);

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      isDisableEdit,
      setRoleDetail,
      setQueryParams,
      getRolesAsync,
      updateStatus,
      getRoleDetailByIdAsync,
      getPermissionsAsync,
      createRoleAsync,
      updateRoleAsync,
    };
  });

export default RoleStore;
export type RoleStoreInstance = Instance<typeof RoleStore>;
