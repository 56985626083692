import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { PageLayout } from 'pages/shared/layout';
import { SiteSettingStore, SiteSettingStoreInstance, SiteSettingStoreProvider } from './stores';
import { useRootStore } from 'stores';
import { createStore, getEnv } from '@vklink/libs-state';

const SiteSettingLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<SiteSettingStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(SiteSettingStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);

  return (
    <SiteSettingStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </SiteSettingStoreProvider>
  );
};

export default SiteSettingLayout;
