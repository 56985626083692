import { CouponType } from 'enums';

export const CouponTypeOptions: SelectOption[] = [
  {
    label: 'Value',
    value: CouponType.VALUE,
  },
  {
    label: 'Percent',
    value: CouponType.PERCENT,
  },
];
