import { types, SnapshotOut, Instance } from '@vklink/libs-state';
import { MediaIndex } from 'enums';

export const MediaModel = types.model('Media Model', {
  id: types.string,
  mediaIndex: types.enumeration<MediaIndex>(Object.values(MediaIndex)),
  path: types.string,
  title: types.string,
  name: types.maybeNull(types.string),
  url: types.string,
  extension: types.string,
  bucketName: types.string,
});

export const BannerSliderModel = types.model('Banner Model', {
  id: types.identifier,
  bannerType: types.string,
  title: types.maybeNull(types.string),
  isEnabled: types.boolean,
  heading: types.maybeNull(types.string),
  hyperlink: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  position: types.number,
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  medias: types.array(MediaModel),
});

export interface BannerSlider extends SnapshotOut<typeof BannerSliderModel> {}
export interface BannerSliderInstance extends Instance<typeof BannerSliderModel> {}
