import { useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import { createStore, getEnv } from '@vklink/libs-state';

import { useRootStore } from 'stores';
import { RoleStore, RoleStoreInstance, RoleStoreProvider } from './stores';
import { PageLayout } from 'pages/shared/layout';

const RoleLayout = () => {
  const rootStore = useRootStore();
  const [initialState, setInitialState] = useState<RoleStoreInstance | null>(null);

  useEffect(() => {
    setInitialState(
      createStore(RoleStore, undefined, {
        ...getEnv(rootStore),
        load: rootStore.loadingStore.load,
        loaded: rootStore.loadingStore.loaded,
      })
    );
  }, []);

  return (
    <RoleStoreProvider value={initialState}>
      <PageLayout>
        <Outlet />
      </PageLayout>
    </RoleStoreProvider>
  );
};

export default RoleLayout;
