import { SnapshotIn, types } from '@vklink/libs-state';

export const PromotionFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  promotionType: types.optional(types.string, ''),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
});

export interface PromotionFilterParams extends SnapshotIn<typeof PromotionFilterParamsModel> {}
export const DefaultPromotionFilterParams: PromotionFilterParams = {
  keyword: '',
  promotionType: '',
  startDate: '',
  endDate: '',
};
