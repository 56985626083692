const API_V1_PRODUCTS = '/prod/api/v1/products';

export const PRODUCT_API = {
  GET_PRODUCTS: `${API_V1_PRODUCTS}`,
  GET_PRODUCT: `${API_V1_PRODUCTS}/:id`,
  POST_PRODUCT: `${API_V1_PRODUCTS}`,
  PUT_PRODUCT: `${API_V1_PRODUCTS}/:id`,
  PUT_PRODUCT_STATUS: `${API_V1_PRODUCTS}/:id/status`,

  GET_PRODUCTS_OPTIONS: `${API_V1_PRODUCTS}/options`,

  GET_BEST_SELLING_PRODUCTS: `${API_V1_PRODUCTS}`,
};
