import { Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import { lazyLoad } from '@vklink/components-shared';

import MasterLayout from './MasterLayout';
import DashboardLayout from 'pages/dashboard/DashboardLayout';
import CategoryLayout from 'pages/category/CategoryLayout';
import {
  BlogsRoutes,
  BrandRoutes,
  CategoryRoutes,
  ProductRoutes,
  OrderRoutes,
  CouponRoutes,
  SiteSettingRoutes,
  BannerSliderRoutes,
  CustomerRoutes,
  PromotionRoutes,
  InternalUserRoutes,
  RoleRoutes,
} from './models';
import BrandLayout from 'pages/brand/BrandLayout';
import ProductLayout from 'pages/product/ProductLayout';
import SiteSettingLayout from 'pages/site-setting/SiteSettingLayout';
import CouponLayout from 'pages/coupons/CouponsLayout';
import BlogsLayout from 'pages/blogs/BlogsLayout';
import OrderLayout from 'pages/order/OrderLayout';
import CustomerLayout from 'pages/customer/CustomerLayout';
import BannerSliderLayout from 'pages/banner-slider/BannerSliderLayout';
import PromotionLayout from 'pages/promotions/PromotionLayout';
import InternalUserLayout from 'pages/internal-user/InternalUserLayout';
import RoleLayout from 'pages/role/RoleLayout';
import { useAuthStore, SigninCallbackPage, SilentCallbackPage } from '@vklink/libs-auth';
import { observer } from '@vklink/libs-state';
import AuthLayout from 'pages/auth/AuthLayout';

const WelcomePage = lazyLoad(() => import('pages/auth/WelcomePage'));

const ForgotPasswordPage = lazyLoad(() => import('pages/auth/ForgotPasswordPage'));

const DashboardListPage = lazyLoad(() => import('pages/dashboard/DashboardListPage'));

const CategoryListPage = lazyLoad(() => import('pages/category/CategoryListPage'));
const CategoryDetailPage = lazyLoad(() => import('pages/category/CategoryDetailPage'));
const CategoryAddPage = lazyLoad(() => import('pages/category/CategoryAddedPage'));

const ProductListPage = lazyLoad(() => import('pages/product/ProductListPage'));
const ProductAddedPage = lazyLoad(() => import('pages/product/ProductAddedPage'));
const ProductDetailPage = lazyLoad(() => import('pages/product/ProductDetailPage'));

const BrandListPage = lazyLoad(() => import('pages/brand/BrandListPage'));
const BrandDetailPage = lazyLoad(() => import('pages/brand/BrandDetailPage'));
const BrandAddedPage = lazyLoad(() => import('pages/brand/BrandAddedPage'));

const GeneralPage = lazyLoad(() => import('pages/site-setting/GeneralPage'));
const FooterPage = lazyLoad(() => import('pages/site-setting/FooterPage'));
const SocialLinksPage = lazyLoad(() => import('pages/site-setting/SocialLinksPage'));
const ShippingVatPage = lazyLoad(() => import('pages/site-setting/ShippingVatPage'));

const CouponListPage = lazyLoad(() => import('pages/coupons/CouponsListPage'));
const CouponAddedPage = lazyLoad(() => import('pages/coupons/CouponsAddedPage'));
const CouponDetailPage = lazyLoad(() => import('pages/coupons/CouponsDetailPage'));

const OrderListPage = lazyLoad(() => import('pages/order/OrderListPage'));
const OrderDetailPage = lazyLoad(() => import('pages/order/OrderDetailPage'));

const BlogsListPage = lazyLoad(() => import('pages/blogs/BlogsListPage'));
const BlogsDetailPage = lazyLoad(() => import('pages/blogs/BlogsDetailPage'));
const BlogsAddPage = lazyLoad(() => import('pages/blogs/BlogsAddPage'));

const BannerSliderListPage = lazyLoad(() => import('pages/banner-slider/BannerSliderListPage'));
const BannerSliderAddedPage = lazyLoad(() => import('pages/banner-slider/BannerSliderAddedPage'));
const BannerSliderDetailPage = lazyLoad(() => import('pages/banner-slider/BannerSliderDetailPage'));

const CustomerListPage = lazyLoad(() => import('pages/customer/CustomerListPage'));
const CustomerDetailPage = lazyLoad(() => import('pages/customer/CustomerDetailPage'));

const PromotionListPage = lazyLoad(() => import('pages/promotions/PromotionListPage'));
const PromotionAddedPage = lazyLoad(() => import('pages/promotions/PromotionAddedPage'));
const PromotionDetailPage = lazyLoad(() => import('pages/promotions/PromotionDetailPage'));

const InternalUserListPage = lazyLoad(() => import('pages/internal-user/InternalUserListPage'));
const InternalUserDetailPage = lazyLoad(() => import('pages/internal-user/InternalUserDetailPage'));
const InternalUserAddedPage = lazyLoad(() => import('pages/internal-user/InternalUserAddedPage'));

const RoleListPage = lazyLoad(() => import('pages/role/RoleListPage'));
const RoleDetailPage = lazyLoad(() => import('pages/role/RoleDetailPage'));
const RoleAddedPage = lazyLoad(() => import('pages/role/RoleAddedPage'));

export const ROUTE_PATHS = {
  root: '/',
  notFound: '*',
  auth: {
    root: '/auth',
    login: '/auth/login',
    logout: '/auth/logout',
    forgotPassword: '/auth/forgot-password',
  },
};

const privateRoutes: RouteObject[] = [
  {
    path: ROUTE_PATHS.root,
    element: <MasterLayout />,
    children: [
      {
        path: ROUTE_PATHS.root,
        element: <DashboardLayout />,
        children: [{ index: true, element: <DashboardListPage /> }],
      },
      {
        path: CategoryRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <CategoryLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <CategoryListPage />,
          },
          {
            path: CategoryRoutes.DETAIL,
            element: <CategoryDetailPage />,
          },
          {
            path: CategoryRoutes.CREATE,
            element: <CategoryAddPage />,
          },
          {
            path: CategoryRoutes.EDIT,
            element: <CategoryAddPage />,
          },
        ],
      },
      {
        path: ProductRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <ProductLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <ProductListPage />,
          },
          {
            path: ProductRoutes.CREATE,
            element: <ProductAddedPage />,
          },
          {
            path: ProductRoutes.EDIT,
            element: <ProductAddedPage />,
          },
          {
            path: ProductRoutes.DETAIL,
            element: <ProductDetailPage />,
          },
        ],
      },
      {
        path: BrandRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <BrandLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <BrandListPage />,
          },
          {
            path: BrandRoutes.DETAIL,
            element: <BrandDetailPage />,
          },
          {
            path: BrandRoutes.CREATE,
            element: <BrandAddedPage />,
          },
          {
            path: BrandRoutes.EDIT,
            element: <BrandAddedPage />,
          },
        ],
      },
      {
        path: SiteSettingRoutes.GENERAL,
        element: (
          <Suspense fallback={<></>}>
            <SiteSettingLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <GeneralPage />,
          },
          {
            path: SiteSettingRoutes.FOOTER,
            element: <FooterPage />,
          },
          {
            path: SiteSettingRoutes.SOCIAL_LINKS,
            element: <SocialLinksPage />,
          },
          {
            path: SiteSettingRoutes.SHIPPING_VAT,
            element: <ShippingVatPage />,
          },
        ],
      },
      {
        path: BannerSliderRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <BannerSliderLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <BannerSliderListPage />,
          },
          {
            path: BannerSliderRoutes.CREATE,
            element: <BannerSliderAddedPage />,
          },
          {
            path: BannerSliderRoutes.EDIT,
            element: <BannerSliderAddedPage />,
          },
          {
            path: BannerSliderRoutes.DETAIL,
            element: <BannerSliderDetailPage />,
          },
        ],
      },
      {
        path: CouponRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <CouponLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <CouponListPage />,
          },
          {
            path: CouponRoutes.CREATE,
            element: <CouponAddedPage />,
          },
          {
            path: CouponRoutes.EDIT,
            element: <CouponAddedPage />,
          },
          {
            path: CouponRoutes.DETAIL,
            element: <CouponDetailPage />,
          },
        ],
      },
      {
        path: PromotionRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <PromotionLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <PromotionListPage />,
          },
          {
            path: PromotionRoutes.CREATE,
            element: <PromotionAddedPage />,
          },
          {
            path: PromotionRoutes.EDIT,
            element: <PromotionAddedPage />,
          },
          {
            path: PromotionRoutes.DETAIL,
            element: <PromotionDetailPage />,
          },
        ],
      },
      {
        path: OrderRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <OrderLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <OrderListPage />,
          },
          {
            path: OrderRoutes.DETAIL,
            element: <OrderDetailPage />,
          },
        ],
      },
      {
        path: BlogsRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <BlogsLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <BlogsListPage />,
          },
          {
            path: BlogsRoutes.CREATE,
            element: <BlogsAddPage />,
          },
          {
            path: BlogsRoutes.EDIT,
            element: <BlogsAddPage />,
          },
          {
            path: BlogsRoutes.DETAIL,
            element: <BlogsDetailPage />,
          },
        ],
      },
      {
        path: CustomerRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <CustomerLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <CustomerListPage />,
          },
          {
            path: CustomerRoutes.DETAIL,
            element: <CustomerDetailPage />,
          },
        ],
      },
      {
        path: InternalUserRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <InternalUserLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <InternalUserListPage />,
          },
          {
            path: InternalUserRoutes.DETAIL,
            element: <InternalUserDetailPage />,
          },
          {
            path: InternalUserRoutes.CREATE,
            element: <InternalUserAddedPage />,
          },
          {
            path: InternalUserRoutes.EDIT,
            element: <InternalUserAddedPage />,
          },
        ],
      },
      {
        path: RoleRoutes.LIST,
        element: (
          <Suspense fallback={<></>}>
            <RoleLayout />
          </Suspense>
        ),
        children: [
          {
            index: true,
            element: <RoleListPage />,
          },
          {
            path: RoleRoutes.DETAIL,
            element: <RoleDetailPage />,
          },
          {
            path: RoleRoutes.CREATE,
            element: <RoleAddedPage />,
          },
          {
            path: RoleRoutes.EDIT,
            element: <RoleAddedPage />,
          },
        ],
      },
    ],
  },
];

const publicRoutes: RouteObject[] = [
  {
    path: ROUTE_PATHS.root,
    element: <AuthLayout />,
    children: [
      {
        path: ROUTE_PATHS.root,
        element: <WelcomePage />,
      },
      {
        path: ROUTE_PATHS.auth.forgotPassword,
        element: <ForgotPasswordPage />,
      },
      {
        path: '/auth/signin-callback',
        element: <SigninCallbackPage />,
      },
      {
        path: '/auth/silent-callback',
        element: <SilentCallbackPage />,
      },
    ],
  },
  { path: ROUTE_PATHS.notFound, element: <WelcomePage /> },
];

const Routes = () => {
  const authStore = useAuthStore();

  const routes = authStore.isAuthenticated ? privateRoutes : publicRoutes;

  return useRoutes(routes);
};

export default observer(Routes);
