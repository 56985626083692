import { createStoreContext } from '@vklink/libs-state';
import { SiteSettingStoreInstance } from './SiteSettingStore';

const [SiteSettingStoreProvider, useSiteSettingStore] =
  createStoreContext<SiteSettingStoreInstance>();

export { SiteSettingStoreProvider, useSiteSettingStore };

export {
  default as SiteSettingStore,
  type SiteSettingStoreEnv,
  type SiteSettingStoreInstance,
} from './SiteSettingStore';
