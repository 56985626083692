import { types, getSnapshot, getEnv, Instance, flow, applySnapshot } from '@vklink/libs-state';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore } from 'stores';
import {
  DefaultOrderFilterParams,
  OrderDetailInstance,
  OrderDetailModel,
  OrderFilterParams,
  OrderFilterParamsModel,
  OrderModel,
  OrderStatusModel,
} from './models';
import { ORDER_API, PAYMENT_API } from 'api';
import { generatePath } from 'react-router';
import { OrderStatusWithoutCancelledOptions, OrderStatusCancelledOptions } from 'constant';
import { OrderStatus } from 'enums';

export type OrderStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const OrderStore = types
  .compose(
    PaginationStore,
    types.model('Order Store', {
      filterParams: types.optional(OrderFilterParamsModel, DefaultOrderFilterParams),
      orders: types.array(OrderModel),
      orderDetail: types.maybeNull(OrderDetailModel),
      numberOrderStatuses: types.array(OrderStatusModel),
      userId: types.optional(types.string, ''),
    })
  )
  .views((self) => {
    return {
      get listOrders() {
        return getSnapshot(self.orders);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
          customerId: self.userId,
        });
      },
      get getOrderDelivery() {
        return (
          self.orderDetail?.orderDeliveryInfo && getSnapshot(self.orderDetail?.orderDeliveryInfo)
        );
      },
      get getListOrderItems() {
        if (!self.orderDetail) return [];

        if (self.orderDetail?.orderItems?.length > 0) {
          //merge array giftDetails to orderItems
          const result = getSnapshot(self.orderDetail.orderItems).flatMap(
            ({ giftDetails, ...el }) => {
              if (!giftDetails) return el;

              const gift = giftDetails.point ? [giftDetails.point] : giftDetails.products;

              return [el, ...gift].map((item: any) => {
                return {
                  ...item,
                  ...giftDetails,
                  productName:
                    item.productName || `[${giftDetails?.promotionType}] ${item.name ?? 'Free'}`,
                  productCode: item.productCode || item.code,
                  quantity: item.quantity || item,
                };
              });
            }
          );

          return result;
        } else {
          return [];
        }
      },
      get getNumberOrderStatuses() {
        return getSnapshot(self.numberOrderStatuses);
      },
      get getStepActions() {
        if (self.orderDetail?.status === OrderStatus.CANCEL) {
          return OrderStatusCancelledOptions.map((el) => el.label);
        } else {
          return OrderStatusWithoutCancelledOptions.map((el) => el.label);
        }
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<OrderStoreEnv>(self);

    const setQueryParams = (filterParams: OrderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultOrderFilterParams, ...filterParams });
    };
    const setUserId = (userId: string) => {
      self.userId = userId;
    };

    const setOrderDetail = (order: OrderDetailInstance) => {
      self.orderDetail = { ...order };
    };

    const getOrdersAsync = flow(function* () {
      const loadingId = load('Get Order Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_ORDERS, {
          params: self.getQueryParams,
        });

        applySnapshot(self.orders, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOrderDetailAsync = flow(function* (id: string) {
      const loadingId = load('Get Order Async');
      try {
        const response = yield httpInstance.get(generatePath(ORDER_API.GET_ORDER, { id }));
        self.orderDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateOrderStatusAsync = flow(function* (
      id: string,
      status: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Update Order Status Async');
      try {
        yield httpInstance.put(generatePath(ORDER_API.PUT_ORDER_STATUS, { id }), {
          status,
        });

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getNumberOrderStatusesAsync = flow(function* () {
      const loadingId = load('Get Number Order Statuses Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_NUMBER_ORDER_STATUSES);

        applySnapshot(self.numberOrderStatuses, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updatePaymentStatusAsync = flow(function* (orderNumber: string, cb?: RequestCallback) {
      const loadingId = load('Update Payment Status Async');
      try {
        yield httpInstance.put(
          PAYMENT_API.UPDATE_PAYMENT_BANK_TRANSFER + `?orderNumber=${orderNumber}`
        );

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setUserId,
      setOrderDetail,
      setQueryParams,
      getOrdersAsync,
      getOrderDetailAsync,
      updateOrderStatusAsync,
      getNumberOrderStatusesAsync,
      updatePaymentStatusAsync,
    };
  });

export default OrderStore;
export type OrderStoreInstance = Instance<typeof OrderStore>;
