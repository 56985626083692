import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const PromotionProductModel = types.model('Promotion Product Model', {
  id: types.identifier,
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  quantity: types.number,
});

export interface PromotionProductInstance extends Instance<typeof PromotionProductModel> {}
export interface PromotionProductInputModel extends SnapshotIn<typeof PromotionProductModel> {}
export interface PromotionProduct extends SnapshotOut<typeof PromotionProductModel> {}
