import { generatePath } from 'react-router';

import { types, Instance, flow, getEnv, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { SETTING_API } from 'api';
import { SettingType } from 'enums';
import { SettingTypeModel, SubmitShippingVat } from './models';

export type SiteSettingStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const SiteSettingStore = types
  .model('SiteSetting Store', {
    settingsType: types.array(SettingTypeModel),
  })
  .views((self) => {
    return {
      get getSettingType() {
        return getSnapshot(self.settingsType);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<SiteSettingStoreEnv>(self);

    const getSettingByTypeAsync = flow(function* (settingType: SettingType) {
      const loadingId = load('Get Customer Detail Async');
      try {
        const response = yield httpInstance.get(
          generatePath(SETTING_API.GET_SETTING_TYPE, { settingType })
        );

        applySnapshot(self.settingsType, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const postSettingOrderAsync = flow(function* (data: SubmitShippingVat, cb?: RequestCallback) {
      const loadingId = load('Post Setting Order Async');
      try {
        yield httpInstance.post(SETTING_API.POST_SHIPPING_VAT, data);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getSettingByTypeAsync,
      postSettingOrderAsync,
    };
  });

export default SiteSettingStore;
export type SiteSettingStoreInstance = Instance<typeof SiteSettingStore>;
