export enum TrackingTime {
  TODAY = 'TODAY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum TrackingTimeSku {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum TrackingTimeProfitMargin {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}
