import { generatePath } from 'react-router';

import { types, Instance, getSnapshot, getEnv, flow, applySnapshot } from '@vklink/libs-state';
import {
  Category,
  CategoryFilterParams,
  CategoryFilterParamsModel,
  CategoryModel,
  CreateCategory,
  DefaultCategoryFilterParams,
} from './models';
import { removeEmptyInObject } from 'pages/shared/utils';
import { CATEGORY_API } from 'api';
import { PaginationStore } from 'stores';
import { CategoryOptionsModel } from 'pages/shared/models/common-options';

export type CategoryStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const CategoryStore = types
  .compose(
    PaginationStore,
    types.model('Category Store', {
      filterParams: types.optional(CategoryFilterParamsModel, DefaultCategoryFilterParams),
      categories: types.array(CategoryModel),
      categoryDetail: types.maybeNull(CategoryModel),
      categoryOptions: types.array(CategoryOptionsModel),
    })
  )
  .views((self) => {
    return {
      get listCategories() {
        return getSnapshot(self.categories);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getCategoryOptions() {
        return self.categoryOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<CategoryStoreEnv>(self);

    const setQueryParams = (filterParams: CategoryFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultCategoryFilterParams, ...filterParams });
    };

    const setCategoryDetail = (category: Category) => {
      self.categoryDetail = { ...category };
    };

    const getCategorysAsync = flow(function* () {
      const loadingId = load('Get Category Async');
      try {
        const response = yield httpInstance.get(CATEGORY_API.GET_CATEGORIES, {
          params: self.getQueryParams,
        });
        applySnapshot(self.categories, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCategoryDetailByIdAsync = flow(function* (categoryId: string) {
      const loadingId = load('Get Detail Category Async');
      try {
        const response = yield httpInstance.get(
          generatePath(CATEGORY_API.GET_CATEGORY, { id: categoryId })
        );

        self.categoryDetail = {
          ...response.data,
          parentId: response.data.parent?.id ?? null,
        };
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createCategory = flow(function* (category: CreateCategory, cb?: RequestCallback) {
      const loadingId = load('Create Category');
      try {
        const addCategory = removeEmptyInObject(category);

        yield httpInstance.post(CATEGORY_API.POST_CATEGORY, {
          ...addCategory,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editCategory = flow(function* (
      category: CreateCategory,
      id: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create Category');
      try {
        const editCategory = removeEmptyInObject(category);
        const url = generatePath(CATEGORY_API.PUT_CATEGORY, { id });

        yield httpInstance.put(url, {
          ...editCategory,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Category');
      const url = generatePath(CATEGORY_API.PUT_CATEGORY_STATUS, { id: self.categoryDetail?.id });

      try {
        yield httpInstance.put(url, {
          status: !self.categoryDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCategoryOptionsAsync = flow(function* () {
      const loadingId = load('Get Category Async');
      try {
        const response = yield httpInstance.get(CATEGORY_API.GET_CATEGORIES_OPTIONS);
        applySnapshot(self.categoryOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      setCategoryDetail,
      getCategorysAsync,
      getCategoryDetailByIdAsync,
      createCategory,
      editCategory,
      updateStatus,
      getCategoryOptionsAsync,
    };
  });

export default CategoryStore;
export type CategoryStoreInstance = Instance<typeof CategoryStore>;
