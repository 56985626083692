const API_V1_BRANDS = '/prod/api/v1/brands';

export const BRAND_API = {
  GET_BRANDS: `${API_V1_BRANDS}`,
  GET_BRAND: `${API_V1_BRANDS}/:id`,
  POST_BRAND: `${API_V1_BRANDS}`,
  PUT_BRAND: `${API_V1_BRANDS}/:id`,
  PUT_CATEGORY_STATUS: `${API_V1_BRANDS}/:id/status`,
  GET_BRANDS_OPTIONS: `${API_V1_BRANDS}/options`,
};
