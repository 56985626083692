import { Typography } from '@mui/material';

type Props = {
  text?: string;
};

const PageTitle = (props: Props) => {
  return (
    <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }} textTransform="uppercase">
      {props.text}
    </Typography>
  );
};

export default PageTitle;
