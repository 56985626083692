import { createStoreContext } from '@vklink/libs-state';
import { CouponStoreInstance } from './CouponsStore';

const [CouponStoreProvider, useCouponStore] = createStoreContext<CouponStoreInstance>();

export { CouponStoreProvider, useCouponStore };

export {
  default as CouponStore,
  type CouponStoreEnv,
  type CouponStoreInstance,
} from './CouponsStore';
