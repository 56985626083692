import { Box } from '@mui/material';

const PageHeader = (props: PageProps) => {
  return (
    <Box
      className="page-section page-header"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  );
};

export default PageHeader;
