import { types, SnapshotIn, SnapshotOut, Instance } from '@vklink/libs-state';

export const RoleModel = types.model('Role Model', {
  id: types.string,
  name: types.string,
  description: types.maybeNull(types.string),
  predefined: types.boolean,
  status: types.string,
  statusChangedAt: types.maybeNull(types.string),
  statusChangedRemarks: types.maybeNull(types.string),
  permissions: types.array(types.string),
  createdAt: types.string,
});

export interface RoleInputModel extends SnapshotIn<typeof RoleModel> {}
export interface Role extends SnapshotOut<typeof RoleModel> {}
export interface RoleInstance extends Instance<typeof RoleModel> {}

export const DefaultRoleValue: CreateRole = {
  name: '',
  description: '',
  permissions: [],
};

export type CreateRole = Pick<RoleInputModel, 'name' | 'description' | 'permissions'>;
