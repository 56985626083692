import { SnapshotIn, types } from '@vklink/libs-state';

export const BlogsFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface BlogsFilterParams extends SnapshotIn<typeof BlogsFilterParamsModel> {}
export const DefaultBlogsFilterParams: BlogsFilterParams = {
  keyword: '',
};
