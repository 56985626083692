const API_V1_CATEGORIES = '/prod/api/v1/categories';

export const CATEGORY_API = {
  GET_CATEGORIES: `${API_V1_CATEGORIES}`,
  GET_CATEGORY: `${API_V1_CATEGORIES}/:id`,
  POST_CATEGORY: `${API_V1_CATEGORIES}`,
  PUT_CATEGORY: `${API_V1_CATEGORIES}/:id`,
  PUT_CATEGORY_STATUS: `${API_V1_CATEGORIES}/:id/status`,
  GET_CATEGORIES_OPTIONS: `${API_V1_CATEGORIES}/options`,
};
