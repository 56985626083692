import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const AddPromotionModel = types.model('Add Promotion Model', {
  productId: types.maybeNull(types.string),
});

export const DefaultPromotion: AddPromotion = {
  productId: '',
};

export interface AddPromotionInstance extends Instance<typeof AddPromotionModel> {}
export interface AddPromotionInputModel extends SnapshotIn<typeof AddPromotionModel> {}
export interface AddPromotion extends SnapshotOut<typeof AddPromotionModel> {}
