import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const OrderModel = types.model('Order Model', {
  id: types.identifier,
  orderNumber: types.string,
  customerName: types.maybeNull(types.string),
  customerPhone: types.maybeNull(types.string),
  totalPrice: types.number,
  orderStatus: types.maybeNull(types.string),
});

export interface OrderInstance extends Instance<typeof OrderModel> {}
export interface OrderInputModel extends SnapshotIn<typeof OrderModel> {}
export interface Order extends SnapshotOut<typeof OrderModel> {}
