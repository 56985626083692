import { SnapshotIn, types } from '@vklink/libs-state';
import { BannerType, MediaIndex } from 'enums';
import { UploadInputModel } from 'pages/shared/models';

export const UploadMediaModel = types.model('Media Model', {
  mediaIndex: types.enumeration<MediaIndex>(Object.values(MediaIndex)),
  fileName: types.string,
  extension: types.string,
  bucket: types.maybeNull(types.string),
  path: types.string,
  fileId: types.maybeNull(types.string),
});

export const CreateBannerSliderModel = types.model('Banner Model', {
  heading: types.string,
  bannerSettingType: types.string,
  bannerTitle: types.string,
  description: types.maybeNull(types.string),
  hyperlink: types.maybeNull(types.string),
  medias: types.array(UploadMediaModel),
  position: types.number,
  imagesPc: types.array(UploadInputModel),
  imagesMobile: types.array(UploadInputModel),
});

export const DefaultBannerSliderValue: CreateBannerSlider = {
  heading: '',
  bannerSettingType: BannerType.MAIN_BANNER,
  bannerTitle: '',
  description: '',
  hyperlink: '',
  position: 0,
  medias: [],
  imagesPc: [],
  imagesMobile: [],
};

export interface CreateBannerSlider extends SnapshotIn<typeof CreateBannerSliderModel> {}
