import { generatePath } from 'react-router';

import {
  types,
  Instance,
  getSnapshot,
  getEnv,
  flow,
  applySnapshot,
  cast,
} from '@vklink/libs-state';
import { BRAND_API, CATEGORY_API, PRODUCT_API, SETTING_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore, UploadFileStore } from 'stores';
import {
  DefaultProductFilterParams,
  CreateProduct,
  ProductFilterParamsModel,
  ProductModel,
  ProductFilterParams,
  ProductInstance,
} from './models';
import { BrandOptionsModel, CategoryOptionsModel } from 'pages/shared/models/common-options';
import { SettingKeyModel } from 'pages/shared/models';
import { SettingOrder } from 'enums';

export type ProductStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const ProductStore = types
  .compose(
    PaginationStore,
    UploadFileStore,
    types.model('Product Store', {
      filterParams: types.optional(ProductFilterParamsModel, DefaultProductFilterParams),
      products: types.array(ProductModel),
      productDetail: types.maybeNull(ProductModel),
      categoryOptions: types.array(CategoryOptionsModel),
      brandOptions: types.array(BrandOptionsModel),
      vatFee: types.maybeNull(SettingKeyModel),
    })
  )
  .views((self) => {
    return {
      get listProducts() {
        return getSnapshot(self.products);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getCategoryOptions() {
        return self.categoryOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getBrandOptions() {
        return self.brandOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
      get getVatFee() {
        return Number(self.vatFee?.value);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<ProductStoreEnv>(self);

    const setQueryParams = (filterParams: ProductFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultProductFilterParams, ...filterParams });
    };

    const setProductDetail = (product: ProductInstance) => {
      self.productDetail = { ...product };
    };

    const getProductsAsync = flow(function* () {
      const loadingId = load('Get Product Async');
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_PRODUCTS, {
          params: self.getQueryParams,
        });
        applySnapshot(self.products, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProductDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Product Detail Async');
      try {
        const response = yield httpInstance.get(generatePath(PRODUCT_API.GET_PRODUCT, { id }));

        response.data.images = response.data.medias.map((el: any) => {
          return {
            dataURL: el.url,
          };
        });

        self.productDetail = cast({
          ...response.data,
          id: response.data.id,
          regularPrice: response.data.regularPrice,
          sellPrice: response.data.sellPrice,
          images: response.data.images,
          medias: (response.data?.medias || []).map((item: any) => {
            return {
              ...item,
              bucket: item.bucketName,
            };
          }),
          attributes: response.data.attributes,
          enabled: response.data.enabled,
        });
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createProductAsync = flow(function* (product: CreateProduct, cb?: RequestCallback) {
      const loadingId = load('Create Product Async');
      try {
        yield httpInstance.post(PRODUCT_API.POST_PRODUCT, product);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editProductAsync = flow(function* (
      product: CreateProduct,
      id: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Edit Product Async');
      try {
        yield httpInstance.put(generatePath(PRODUCT_API.PUT_PRODUCT, { id }), product);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCategoryOptionsAsync = flow(function* () {
      const loadingId = load('Get Category Options Async');
      try {
        const response = yield httpInstance.get(CATEGORY_API.GET_CATEGORIES_OPTIONS);
        applySnapshot(self.categoryOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getBrandOptionsAsync = flow(function* () {
      const loadingId = load('Get Brand Options Async');
      try {
        const response = yield httpInstance.get(BRAND_API.GET_BRANDS_OPTIONS);
        applySnapshot(self.brandOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Product');
      const url = generatePath(PRODUCT_API.PUT_PRODUCT_STATUS, { id: self.productDetail?.id });

      try {
        yield httpInstance.put(url, {
          status: !self.productDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getSettingByKeyAsync = flow(function* (key: SettingOrder) {
      const loadingId = load('Get Setting By Key Async');

      try {
        const url = generatePath(SETTING_API.GET_SETTING_TYPE_BY_KEY, { key });

        const response = yield httpInstance.get(url);

        self.vatFee = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setProductDetail,
      setQueryParams,
      getProductsAsync,
      getProductDetailByIdAsync,
      createProductAsync,
      editProductAsync,
      getCategoryOptionsAsync,
      getBrandOptionsAsync,
      getSettingByKeyAsync,
      updateStatus,
    };
  });

export default ProductStore;
export type ProductStoreInstance = Instance<typeof ProductStore>;
