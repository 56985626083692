import { types, getEnv, flow, getSnapshot, applySnapshot } from '@vklink/libs-state';
import { PRODUCT_API } from 'api';
import { toJS } from 'mobx';
import { ProductOptionsModel } from 'pages/shared/models';
import { PromotionProductInputModel, PromotionProductModel } from './models';

export type PromotionStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const PromotionAddedStore = types
  .model('Promotion Added Store', {
    skuOptions: types.array(ProductOptionsModel),
    promotionProducts: types.array(PromotionProductModel),
  })
  .views((self) => {
    return {
      get getSkuOptions() {
        return getSnapshot(self.skuOptions).map((el) => {
          return {
            value: el.id,
            label: el.code + ' - ' + el.name,
          };
        });
      },
      get getSkuGiftOptions() {
        const result = getSnapshot(self.skuOptions).filter((el) => {
          return !self.promotionProducts.some((item) => item.id === el.id);
        });

        return result.map((el) => {
          return {
            value: el.id,
            label: el.code + ' - ' + el.name,
          };
        });
      },
      get getListPromotionProducts() {
        return getSnapshot(self.promotionProducts);
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<PromotionStoreEnv>(self);

    const setDefaultPromotionProducts = (promotionProducts: Array<PromotionProductInputModel>) => {
      applySnapshot(self.promotionProducts, toJS(promotionProducts));
    };

    const setPromotionProductToList = (promotionProduct: PromotionProductInputModel) => {
      applySnapshot(self.promotionProducts, [...self.promotionProducts, promotionProduct]);
    };

    const deletePromotionProduct = (rowIndex: number) => {
      self.promotionProducts.splice(rowIndex, 1);
    };

    const getPromotionProductOptionsAsync = flow(function* () {
      const loadingId = load('Get Promotion Products Options Async');
      try {
        const response = yield httpInstance.get(PRODUCT_API.GET_PRODUCTS_OPTIONS);

        applySnapshot(self.skuOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setDefaultPromotionProducts,
      deletePromotionProduct,
      setPromotionProductToList,
      getPromotionProductOptionsAsync,
    };
  });

export default PromotionAddedStore;
