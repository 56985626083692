import { OrderStatus } from 'enums';

export const getColorByStatus = (status: string) => {
  switch (status) {
    case OrderStatus.WAITING:
      return 'primary.main';
    case OrderStatus.CONFIRMED:
      return 'secondary.main';
    case OrderStatus.PACKED:
      return 'info.main';
    case OrderStatus.SHIPPING:
      return '#FFBD33';
    case OrderStatus.DELIVERED:
      return 'warning.main';
    case OrderStatus.RECEIVED:
      return 'success.main';
    case OrderStatus.CANCEL:
      return 'error.main';
    default:
      return 'default';
  }
};

export const fillColorByStatus = (status: string) => {
  switch (status) {
    case OrderStatus.WAITING:
      return '#2E4576';
    case OrderStatus.CONFIRMED:
      return '#B196DB';
    case OrderStatus.PACKED:
      return '#0288D1';
    case OrderStatus.SHIPPING:
      return '#FFBD33';
    case OrderStatus.DELIVERED:
      return '#ED6C02';
    case OrderStatus.RECEIVED:
      return '#0AB39C';
    case OrderStatus.CANCEL:
      return '#D32F2F';
    default:
      return '#fff';
  }
};

export const getColorByIndex = (index: number) => {
  switch (index) {
    case 0:
      return '#2E4576';
    case 1:
      return '#B196DB';
    case 2:
      return '#0288D1';
    case 3:
      return '#FFBD33';
    case 4:
      return '#ED6C02';
    case 5:
      return '#0AB39C';
    case 6:
      return '#D32F2F';
    default:
      return '#fff';
  }
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};
