import { GiftDetailModel } from './GiftDetailModel';
import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { ConditionType } from 'enums';

export const PromotionDetailModel = types.model('Promotion Detail Model', {
  conditionType: types.string,
  orderProductId: types.string,
  orderProductCode: types.maybeNull(types.string),
  orderProductName: types.maybeNull(types.string),
  giftDetails: types.maybeNull(GiftDetailModel),
  id: types.maybeNull(types.string),
});

export const DefaultPromotionDetailValue: Omit<
  PromotionDetail,
  'orderProductName' | 'orderProductCode' | 'id'
> = {
  conditionType: ConditionType.PRODUCT,
  orderProductId: '',
  giftDetails: {
    products: [],
    point: null,
  },
};

export interface PromotionDetailInstance extends Instance<typeof PromotionDetailModel> {}
export interface PromotionDetailInputModel extends SnapshotIn<typeof PromotionDetailModel> {}
export interface PromotionDetail extends SnapshotOut<typeof PromotionDetailModel> {}
