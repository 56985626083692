import { generatePath } from 'react-router';

import { types, Instance, getEnv, flow, getSnapshot, applySnapshot } from '@vklink/libs-state';
import { COUPON_API } from 'api';
import { DefaultPaginationInfo, PaginationModel } from 'pages/shared/models/pagination';
import {
  CouponFilterParamsModel,
  DefaultCouponFilterParams,
  CouponModel,
  CreateCoupon,
  CouponFilterParams,
  Coupon,
} from './models';
import { PaginationStore } from 'stores';
import { removeEmptyInObject } from 'pages/shared/utils';

export type CouponStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const CouponStore = types
  .compose(
    PaginationStore,
    types.model('Coupon Store', {
      filterParams: types.optional(CouponFilterParamsModel, DefaultCouponFilterParams),
      pagination: types.optional(PaginationModel, DefaultPaginationInfo),

      coupons: types.array(CouponModel),
      couponDetail: types.maybe(CouponModel),
    })
  )
  .views((self) => {
    return {
      get listCoupons() {
        return getSnapshot(self.coupons);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<CouponStoreEnv>(self);

    const setQueryParams = (filterParams: CouponFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultCouponFilterParams, ...filterParams });
    };

    const setCouponDetail = (Coupon: Coupon) => {
      self.couponDetail = { ...Coupon };
    };

    const getCouponsAsync = flow(function* () {
      const loadingId = load('Get Coupon Async');
      try {
        const response = yield httpInstance.get(COUPON_API.GET_COUPONS, {
          params: self.getQueryParams,
        });

        applySnapshot(self.coupons, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCouponDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Coupon Detail By Id Async');
      try {
        const url = generatePath(COUPON_API.GET_COUPON, { id });

        const response = yield httpInstance.get(url);

        self.couponDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createCouponAsync = flow(function* (product: CreateCoupon, cb?: RequestCallback) {
      const loadingId = load('Create Coupon Async');
      try {
        yield httpInstance.post(COUPON_API.POST_COUPON, product);

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Category');
      const url = generatePath(COUPON_API.PUT_COUPON_STATUS, {
        id: self.couponDetail?.id,
      });

      try {
        yield httpInstance.put(url, {
          status: !self.couponDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setCouponDetail,
      setQueryParams,
      createCouponAsync,
      getCouponDetailByIdAsync,
      getCouponsAsync,
      updateStatus,
    };
  });

export default CouponStore;

export type CouponStoreInstance = Instance<typeof CouponStore>;
