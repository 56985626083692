import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const OrderModel = types.model('Order Model', {
  id: types.identifier,
  orderNumber: types.string,
  customerId: types.string,
  customerName: types.string,
  customerPhone: types.maybeNull(types.string),
  sellDate: types.string,
  status: types.string,
  paymentStatus: types.string,
  paymentMethod: types.maybeNull(types.string),
  subtotal: types.number,
  totalPrice: types.number,
  totalDiscount: types.maybeNull(types.number),
  deliveryFee: types.number,
  pointDiscount: types.number,
  couponValue: types.string,
  rebaseOrderNumber: types.string,
  createdAt: types.string,
  createdById: types.string,
  createdBy: types.string,
  updatedAt: types.maybeNull(types.string),
  updatedById: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export interface OrderInstance extends Instance<typeof OrderModel> {}
export interface OrderInputModel extends SnapshotIn<typeof OrderModel> {}
export interface Order extends SnapshotOut<typeof OrderModel> {}
