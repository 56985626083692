const API_V1_CUSTOMERS = '/customer/api/v1/users';

export const CUSTOMER_API = {
  GET_CUSTOMERS: `${API_V1_CUSTOMERS}`,
  GET_CUSTOMER: `${API_V1_CUSTOMERS}/detail/:id`,
  PUT_CUSTOMER_STATUS: `${API_V1_CUSTOMERS}/:id/status`,

  GET_TOTAL_USER_MONTHLY: `${API_V1_CUSTOMERS}/total-new-user-in-year`,

  PUT_PASSWORD_CUSTOMER: `${API_V1_CUSTOMERS}/:id/reset-password`,
};
