import { PromotionType, ConditionType } from 'enums';

export const PromotionTypeOptions: SelectOption[] = [
  {
    label: 'Gift',
    value: PromotionType.GIFT,
  },
  {
    label: 'Point',
    value: PromotionType.POINT,
  },
];

export const ConditionTypeOptions: SelectOption[] = [
  {
    label: 'Product',
    value: ConditionType.PRODUCT,
  },
  {
    label: 'Order',
    value: ConditionType.ORDER,
  },
];
