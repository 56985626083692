import { SnapshotIn, types } from '@vklink/libs-state';

export const CustomerFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface CustomerFilterParams extends SnapshotIn<typeof CustomerFilterParamsModel> {}
export const DefaultCustomerFilterParams: CustomerFilterParams = {
  keyword: '',
};
