import { Gender } from 'enums';

export const GenderOptions: SelectOption[] = [
  {
    label: 'Male',
    value: Gender.MALE,
  },
  {
    label: 'Female',
    value: Gender.FEMALE,
  },
  {
    label: 'Other',
    value: Gender.OTHER,
  },
];
