import { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, Avatar, Badge } from '@mui/material';
import { Notifications } from '@mui/icons-material';
import avatar from 'assets/avatar.jpg';
import English from 'assets/gb.svg';
import VietNam from 'assets/vn.svg';
import logoVKLink from 'assets/logo-vklink.png';

const Header = () => {
  const [language, setLanguage] = useState('en');

  const handleChangeLanguage = () => {
    if (language === 'en') {
      setLanguage('vi');
    } else {
      setLanguage('en');
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: (theme) => theme.palette.common.white,
      }}
    >
      <Toolbar>
        <Box
          component="img"
          sx={{
            height: 50,
          }}
          alt="Logo VKLink."
          src={logoVKLink}
        />
        <Box flexGrow={1} />
        <Box
          component="img"
          sx={{
            height: 20,
            width: 40,
          }}
          alt="The house from the offer."
          src={language === 'en' ? English : VietNam}
          onClick={handleChangeLanguage}
        />
        <Badge badgeContent={4} color="warning" sx={{ ml: 2 }}>
          <Notifications color="action" />
        </Badge>
        <Box sx={{ flexGrow: 0, ml: 4 }} color="GrayText">
          <Typography component="div" sx={{ px: 1 }}>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt="Administrator" src={avatar} />
            </IconButton>
            <Typography component="span" sx={{ ml: 2 }}>
              Hi, Julia
            </Typography>
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
