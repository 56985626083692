import { Box } from '@mui/material';

function PageLayout(props: PageProps) {
  return (
    <Box
      sx={{
        ...props.sx,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: (theme) =>
          `calc(100vh - ${theme.mixins.toolbar.minHeight}px - ${
            theme.mixins.toolbar.minHeight
          }px - ${theme.spacing(1)})}`,
        '& .page-section': {
          px: 3,
          py: 2,
          pb: 0,
          '&:nth-of-type(1)': {
            pt: 3,
          },
        },
      }}
    >
      {props.children}
    </Box>
  );
}

export default PageLayout;
