import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const InternalUserModel = types.model('InternalUser Model', {
  id: types.identifier,
  code: types.string,
  userName: types.string,
  firstName: types.string,
  lastName: types.string,
  fullName: types.string,
  displayName: types.optional(types.string, ''),
  avatar: types.optional(types.string, ''),
  enabled: types.maybeNull(types.boolean),
  birthDate: types.maybeNull(types.string),
  email: types.optional(types.string, ''),
  phoneNumber: types.optional(types.string, ''),
  // phoneNumberConfirmed: types.boolean,
  // accessFailedCount: types.number,
  // lockoutEnd: types.maybeNull(DateTime),
  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  // lastActivityDate: types.maybeNull(DateTime),
  // lastPasswordChangedDate: types.maybeNull(DateTime),
  notes: types.optional(types.string, ''),
  roles: types.array(types.string),
  permissions: types.array(types.string),
  isBlocked: types.optional(types.boolean, false),
});

export interface InternalUserInstance extends Instance<typeof InternalUserModel> {}
export interface InternalUserInputModel extends SnapshotIn<typeof InternalUserModel> {}
export interface InternalUser extends SnapshotOut<typeof InternalUserModel> {}
