import { SnapshotOut, types } from '@vklink/libs-state';

export const SettingTypeModel = types.model({
  id: types.identifier,
  settingType: types.string,
  key: types.string,
  value: types.string,
  description: types.maybeNull(types.string),
  createdAt: types.string,
  createdBy: types.string,
});

export interface SettingType extends SnapshotOut<typeof SettingTypeModel> {}
