import { types } from '@vklink/libs-state';

export const ProductModel = types.model('Product Model', {
  id: types.identifier,
  quantity: types.number,
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});

export const GiftDetailModel = types.model('Gift Detail Model', {
  products: types.array(ProductModel),
  point: types.maybeNull(types.number),
});
