import { generatePath } from 'react-router';

import { types, Instance, flow, getEnv, getSnapshot, applySnapshot } from '@vklink/libs-state';
import { BANNER_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore, UploadFileStore } from 'stores';
import {
  BannerSliderFilterParams,
  BannerSliderFilterParamsModel,
  BannerSliderInstance,
  BannerSliderModel,
  CreateBannerSlider,
  DefaultBannerSliderFilterParams,
} from './models';
import { MediaIndex } from 'enums';

export type BannerSliderStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const BannerSliderStore = types
  .compose(
    PaginationStore,
    UploadFileStore,
    types.model('BannerSlider Store', {
      filterParams: types.optional(BannerSliderFilterParamsModel, DefaultBannerSliderFilterParams),
      bannerSliders: types.array(BannerSliderModel),
      bannerDetail: types.maybeNull(BannerSliderModel),
    })
  )
  .views((self) => ({
    get getBannerSliders() {
      return getSnapshot(self.bannerSliders);
    },
    get getQueryParams() {
      return removeEmptyInObject({
        ...self.filterParams,
        ...self.paginationParams,
      });
    },
    get getBannerPc() {
      const result = self.bannerDetail?.medias.filter(
        (el) => el.mediaIndex === MediaIndex.BANNER_PC
      );

      return result || [];
    },
    get getBannerMobile() {
      const result = self.bannerDetail?.medias.filter(
        (el) => el.mediaIndex === MediaIndex.BANNER_MOBILE
      );

      return result || [];
    },
  }))
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<BannerSliderStoreEnv>(self);

    const setBannerDetail = (item: BannerSliderInstance) => {
      self.bannerDetail = item;
    };

    const setQueryParams = (filterParams: BannerSliderFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultBannerSliderFilterParams, ...filterParams });
    };

    const getBannerSlidersAsync = flow(function* () {
      const loadingId = load('Get Banner Slider Async');
      try {
        const response = yield httpInstance.get(BANNER_API.GET_BANNERS, {
          params: self.getQueryParams,
        });

        applySnapshot(self.bannerSliders, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getBannerSliderByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Banner Slider By Id Async');
      try {
        const response = yield httpInstance.get(generatePath(BANNER_API.GET_BANNER, { id }));

        self.bannerDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Banner Slider Async');
      const url = generatePath(BANNER_API.PUT_BANNER_STATUS, { id: self.bannerDetail?.id });

      try {
        yield httpInstance.put(url, {
          status: !self.bannerDetail?.isEnabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const postBannerSliderAsync = flow(function* (
      bannerSlider: CreateBannerSlider,
      cb?: RequestCallback
    ) {
      const loadingId = load('Post Banner Slider Async');

      try {
        yield httpInstance.post(BANNER_API.POST_BANNER, bannerSlider);
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateBannerSliderAsync = flow(function* (
      bannerSlider: CreateBannerSlider,
      id: string,
      cb?: RequestCallback
    ) {
      const loadingId = load('Post Banner Slider Async');

      try {
        yield httpInstance.put(generatePath(BANNER_API.PUT_BANNER, { id }), bannerSlider);

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setQueryParams,
      setBannerDetail,
      postBannerSliderAsync,
      updateStatus,
      getBannerSlidersAsync,
      getBannerSliderByIdAsync,
      updateBannerSliderAsync,
    };
  });

export default BannerSliderStore;
export type BannerSliderStoreInstance = Instance<typeof BannerSliderStore>;
