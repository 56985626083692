import { Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import TopLoading from './components/TopLoading';
import Footer from './Footer';

const MasterLayout = () => {
  return (
    <>
      <TopLoading />
      <Box sx={{ display: 'flex' }}>
        <Header />
        <Sidebar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflow: 'hidden',
            backgroundColor: '#F5F9FF',
          }}
        >
          <Toolbar />
          <Outlet />
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default MasterLayout;
