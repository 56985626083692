import { generatePath } from 'react-router';

import { types, Instance, getSnapshot, applySnapshot, flow, getEnv } from '@vklink/libs-state';
import { INTERNAL_USER_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore } from 'stores';
import {
  DefaultInternalUserFilterParams,
  InternalUserInstance,
  InternalUserFilterParams,
  InternalUserFilterParamsModel,
  InternalUserModel,
} from './models';
import ecommerce from '../../../../../../ecommerce-admin-db.json';

export type InternalUserStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const InternalUserStore = types
  .compose(
    PaginationStore,
    types.model('InternalUser Store', {
      filterParams: types.optional(InternalUserFilterParamsModel, DefaultInternalUserFilterParams),
      listInternalUsers: types.array(InternalUserModel),
      internalUser: types.maybeNull(InternalUserModel),
    })
  )
  .views((self) => ({
    get getListInternalUsers() {
      return getSnapshot(self.listInternalUsers);
    },
    get getQueryParams() {
      return removeEmptyInObject({
        ...self.filterParams,
        ...self.paginationParams,
      });
    },
  }))
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<InternalUserStoreEnv>(self);

    const setQueryParams = (filterParams: InternalUserFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultInternalUserFilterParams, ...filterParams });
    };

    const setInternalUserDetail = (internalUser: InternalUserInstance) => {
      self.internalUser = { ...internalUser };
    };

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Category');
      const url = generatePath(INTERNAL_USER_API.GET_INTERNAL_USER, { id: self.internalUser?.id });

      try {
        yield httpInstance.put(url, {
          status: !self.internalUser?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getInternalUsersAsync = flow(function* () {
      const loadingId = load('Get Internal Users Async');
      try {
        applySnapshot(self.listInternalUsers, ecommerce.internalUsers);

        const response = yield httpInstance.get(INTERNAL_USER_API.GET_INTERNAL_USERS, {
          params: self.getQueryParams,
        });
        applySnapshot(self.listInternalUsers, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getInternalUserDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Detail Category Async');
      try {
        const internalUser = ecommerce.internalUsers.find((item) => item.id === id) as any;

        self.internalUser = internalUser;

        const response = yield httpInstance.get(
          generatePath(INTERNAL_USER_API.GET_INTERNAL_USER, { id })
        );

        self.internalUser = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setInternalUserDetail,
      setQueryParams,
      getInternalUsersAsync,
      updateStatus,
      getInternalUserDetailByIdAsync,
    };
  });

export default InternalUserStore;
export type InternalUserStoreInstance = Instance<typeof InternalUserStore>;
