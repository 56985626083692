const API_V1_ORDERS = '/order/api/v1/orders';

export const ORDER_API = {
  GET_ORDERS: `${API_V1_ORDERS}`,
  GET_ORDER: `${API_V1_ORDERS}/:id`,
  PUT_ORDER_STATUS: `${API_V1_ORDERS}/:id/status`,
  GET_NUMBER_ORDER_STATUSES: `${API_V1_ORDERS}/count-by-status`,
  GET_GROSS_MERCHANDISES: `${API_V1_ORDERS}/gross-merchandises`,

  GET_PROFIT_MARGIN: `${API_V1_ORDERS}/profit-margin`,

  GET_TOP_SKU: `${API_V1_ORDERS}/top-sku`,
  GET_TOP_BRAND: `${API_V1_ORDERS}/sales-by-brand`,
  GET_TOTAL_ORDER_MONTHLY: `${API_V1_ORDERS}/total-order-by-month`,
  GET_ORDERING_USER_MONTHLY: `${API_V1_ORDERS}/total-user-ordered`,
  GET_TOTAL_SALES: `${API_V1_ORDERS}/total-sales`,
  GET_TOP_ORDERS: `${API_V1_ORDERS}/top`,
  GET_BEST_SELLING: `${API_V1_ORDERS}/best-selling`,
};
