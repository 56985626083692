import React from 'react';

import { Stack } from '@mui/material';
import PageTitle from './PageTitle';

type Props = {
  title: string;
  breadcrumbs?: React.ReactNode;

  right?: React.ReactNode;
};

const PageHeaderLayout = (props: Props) => {
  return (
    <Stack flex={1} direction="row" spacing={1} alignItems="center">
      <Stack flex={1}>
        <PageTitle text={props.title} />
        {props.breadcrumbs}
      </Stack>
      {props.right && (
        <Stack direction="row" spacing={1}>
          {props.right}
        </Stack>
      )}
    </Stack>
  );
};

export default PageHeaderLayout;
