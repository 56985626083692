import { types, Instance, getEnv, flow, applySnapshot, getSnapshot } from '@vklink/libs-state';
import { CUSTOMER_API, ORDER_API } from 'api';
import { mapppingDataMonthly } from 'pages/shared/utils';
import {
  BrandChartModel,
  DataChartMonthlyModel,
  OrderStatusModel,
  ProfitMarginModel,
  SkuChartModel,
  TotalSalesModel,
} from './models';

export type DashboardStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const DashboardStore = types
  .model('Dashboard Store', {
    numberOrderStatuses: types.array(OrderStatusModel),
    totalSales: types.maybeNull(TotalSalesModel),
    revenueChart: types.maybeNull(DataChartMonthlyModel),
    orderChart: types.maybeNull(DataChartMonthlyModel),
    userChart: types.maybeNull(DataChartMonthlyModel),
    orderingUserChart: types.maybeNull(DataChartMonthlyModel),
    topSkuChart: types.array(SkuChartModel),
    topBrandChart: types.array(BrandChartModel),
    profitMarginChart: types.array(ProfitMarginModel),
  })
  .views((self) => {
    return {
      get getRevenueChart() {
        if (self.revenueChart) {
          const result = mapppingDataMonthly(self.revenueChart).map((el) => {
            return {
              ...el,
              value: (Number(el.value) / 1000000).toFixed(2),
            };
          });

          return result;
        }

        return [];
      },
      get getOrderChart() {
        return !!self.orderChart ? mapppingDataMonthly(self.orderChart) : [];
      },
      get getUserChart() {
        return !!self.userChart && !!self.orderingUserChart
          ? mapppingDataMonthly(self.userChart, self.orderingUserChart)
          : [];
      },

      get getNumberOrderStatuses() {
        return getSnapshot(self.numberOrderStatuses);
      },
      get getTopSkuChart() {
        return getSnapshot(self.topSkuChart).map((el) => {
          return {
            label: el.skuName,
            value: el.total,
          };
        });
      },
      get getTopBrandChart() {
        return getSnapshot(self.topBrandChart).map((el) => {
          return {
            label: el.brandName,
            value: el.percent,
          };
        });
      },
      get getProfitMarginChart() {
        return getSnapshot(self.profitMarginChart).map((el) => {
          return {
            ...el,
            sale: el.sale / 1000000,
            gross: el.gross / 1000000,
            net: el.net / 1000000,
          };
        });
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<DashboardStoreEnv>(self);

    const getNumberOrderStatusesAsync = flow(function* (date: string) {
      const loadingId = load('Get Number Order Statuses Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_NUMBER_ORDER_STATUSES, {
          params: {
            date,
          },
        });

        applySnapshot(self.numberOrderStatuses, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getGrossMerchandiseValueAsync = flow(function* (selectedYear: number) {
      const loadingId = load('Get Gross Merchandise Value Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_GROSS_MERCHANDISES, {
          params: {
            selectedYear,
          },
        });

        self.revenueChart = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getTotalSalesAsync = flow(function* (totalSalesOptions: string) {
      const loadingId = load('Get Total Sales Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_TOTAL_SALES, {
          params: {
            totalSalesOptions,
          },
        });

        self.totalSales = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getTotalOrderMonthlyAsync = flow(function* () {
      const loadingId = load('Get Total Order Monthly Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_TOTAL_ORDER_MONTHLY);

        self.orderChart = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getTotalUserMonthlyAsync = flow(function* () {
      const loadingId = load('Get Total User Monthly Async');
      try {
        const response = yield httpInstance.get(CUSTOMER_API.GET_TOTAL_USER_MONTHLY);

        self.userChart = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getOrderingUserMonthlyAsync = flow(function* () {
      const loadingId = load('Get Ordering User Monthly Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_ORDERING_USER_MONTHLY);

        self.orderingUserChart = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getTopSkuAsync = flow(function* (topSKUOptions?: string) {
      const loadingId = load('Get Top Sku Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_TOP_SKU, {
          params: {
            topSKUOptions,
          },
        });

        applySnapshot(self.topSkuChart, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getTopBrandAsync = flow(function* (salesByBrandOptions?: string) {
      const loadingId = load('Get Top Brand Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_TOP_BRAND, {
          params: {
            salesByBrandOptions,
          },
        });

        applySnapshot(self.topBrandChart, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getProfitMarginAsync = flow(function* (
      profitMarginOptions: string,
      year: string | number
    ) {
      const loadingId = load('Get Profit Margin Async');
      try {
        const response = yield httpInstance.get(ORDER_API.GET_PROFIT_MARGIN, {
          params: {
            profitMarginOptions,
            year,
          },
        });

        applySnapshot(self.profitMarginChart, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      getNumberOrderStatusesAsync,
      getGrossMerchandiseValueAsync,
      getTotalSalesAsync,
      getTotalOrderMonthlyAsync,
      getTotalUserMonthlyAsync,
      getOrderingUserMonthlyAsync,
      getTopSkuAsync,
      getTopBrandAsync,
      getProfitMarginAsync,
    };
  });

export default DashboardStore;
export type DashboardStoreInstance = Instance<typeof DashboardStore>;
