import { SnapshotIn, types } from '@vklink/libs-state';

export const BrandFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface BrandFilterParams extends SnapshotIn<typeof BrandFilterParamsModel> {}
export const DefaultBrandFilterParams: BrandFilterParams = {
  keyword: '',
};
