import { types, Instance } from '@vklink/libs-state';

export const DataChartMonthlyModel = types.model('Data Chart Model', {
  january: types.number,
  february: types.number,
  march: types.number,
  april: types.number,
  may: types.number,
  june: types.number,
  july: types.number,
  august: types.number,
  september: types.number,
  october: types.number,
  november: types.number,
  december: types.number,
});

export interface DataChartMonthlyInstance extends Instance<typeof DataChartMonthlyModel> {}
