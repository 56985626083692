import { types, Instance } from '@vklink/libs-state';
import { DefaultPaginationInfo, PaginationModel } from 'pages/shared/models/pagination';
import { BlogsFilterParamsModel, DefaultBlogsFilterParams } from './models';

export type BlogsStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const BlogsStore = types
  .model('Blogs Store', {
    filterParams: types.optional(BlogsFilterParamsModel, DefaultBlogsFilterParams),
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
  })
  .views(() => {
    return {};
  });

export default BlogsStore;
export type BlogsStoreInstance = Instance<typeof BlogsStore>;
