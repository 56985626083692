const API_V1_ROLE = '/id/api/v1/roles';
const API_V1_PERMISSIONS = '/id/api/v1/permissions';

export const ROLE_API = {
  GET_ROLES: `${API_V1_ROLE}`,
  GET_ROLE: `${API_V1_ROLE}/:id`,
  POST_ROLE: `${API_V1_ROLE}`,
  PUT_ROLE: `${API_V1_ROLE}/:id`,
  PUT_ROLE_STATUS: `${API_V1_ROLE}/:id/status`,
};

export const PERMISSION_API = {
  GET_PERMISSIONS: `${API_V1_PERMISSIONS}`,
};
