import { generatePath } from 'react-router';

import { types, Instance, getEnv, flow, getSnapshot, applySnapshot } from '@vklink/libs-state';
import { PROMOTION_API } from 'api';
import { DefaultPaginationInfo, PaginationModel } from 'pages/shared/models/pagination';
import {
  PromotionFilterParamsModel,
  DefaultPromotionFilterParams,
  PromotionModel,
  CreatePromotion,
  PromotionFilterParams,
  PromotionInstance,
} from './models';
import { PaginationStore } from 'stores';
import { removeEmptyInObject } from 'pages/shared/utils';
import PromotionAddedStore from './PromotionAddedStore';

export type PromotionStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const PromotionStore = types
  .compose(
    PaginationStore,
    PromotionAddedStore,
    types.model('Promotion Store', {
      filterParams: types.optional(PromotionFilterParamsModel, DefaultPromotionFilterParams),
      pagination: types.optional(PaginationModel, DefaultPaginationInfo),

      promotions: types.array(PromotionModel),
      promotionDetail: types.maybeNull(PromotionModel),
    })
  )
  .views((self) => {
    return {
      get listPromotions() {
        return getSnapshot(self.promotions);
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<PromotionStoreEnv>(self);

    const setQueryParams = (filterParams: PromotionFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultPromotionFilterParams, ...filterParams });
    };

    const setPromotionDetail = (promotion?: PromotionInstance) => {
      if (!!promotion) self.promotionDetail = { ...promotion };
      else self.promotionDetail = null;
    };

    const getPromotionsAsync = flow(function* () {
      const loadingId = load('Get Promotion Async');
      try {
        const response = yield httpInstance.get(PROMOTION_API.GET_PROMOTIONS, {
          params: self.getQueryParams,
        });

        applySnapshot(self.promotions, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getPromotionDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Promotion Detail By Id Async');
      try {
        const url = generatePath(PROMOTION_API.GET_PROMOTION, { id });
        const response = yield httpInstance.get(url);

        self.promotionDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createPromotionAsync = flow(function* (product: CreatePromotion, cb?: RequestCallback) {
      const loadingId = load('Create Promotion Async');
      try {
        yield httpInstance.post(PROMOTION_API.POST_PROMOTION, product);

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updatePromotionAsync = flow(function* (
      id: string,
      product: CreatePromotion,
      cb?: RequestCallback
    ) {
      const loadingId = load('Create Promotion Async');
      try {
        const url = generatePath(PROMOTION_API.PUT_PROMOTION, { id });

        yield httpInstance.put(url, product);

        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Category');
      const url = generatePath(PROMOTION_API.PUT_PROMOTION_STATUS, {
        id: self.promotionDetail?.id,
      });

      try {
        yield httpInstance.put(url, {
          status: !self.promotionDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setPromotionDetail,
      setQueryParams,
      createPromotionAsync,
      getPromotionDetailByIdAsync,
      getPromotionsAsync,
      updateStatus,
      updatePromotionAsync,
    };
  });

export default PromotionStore;

export type PromotionStoreInstance = Instance<typeof PromotionStore>;
