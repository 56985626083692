import { generatePath } from 'react-router';

import { types, Instance, getSnapshot, flow, getEnv, applySnapshot } from '@vklink/libs-state';
import { BRAND_API, CATEGORY_API } from 'api';
import { removeEmptyInObject } from 'pages/shared/utils';
import { PaginationStore, UploadFileStore } from 'stores';
import {
  BrandFilterParams,
  BrandFilterParamsModel,
  BrandInstance,
  BrandModel,
  DefaultBrandFilterParams,
} from './models';
import { CreateBrand } from './models';
import { CategoryOptionsModel } from 'pages/shared/models/common-options';

export type BrandStoreEnv = {
  httpInstance: HttpInstance;
  load: (notes?: string) => string;
  loaded: (id: string) => void;
};

const BrandStore = types
  .compose(
    PaginationStore,
    UploadFileStore,
    types.model('Brand Store', {
      filterParams: types.optional(BrandFilterParamsModel, DefaultBrandFilterParams),
      brands: types.array(BrandModel),
      brandDetail: types.maybeNull(BrandModel),
      categoryOptions: types.array(CategoryOptionsModel),
    })
  )
  .views((self) => {
    return {
      get listBrands() {
        return self.brands.length > 0 ? getSnapshot(self.brands) : [];
      },
      get getQueryParams() {
        return removeEmptyInObject({
          ...self.filterParams,
          ...self.paginationParams,
        });
      },
      get getCategoryOptions() {
        return self.categoryOptions.map((el) => {
          return { value: el.id, label: el.name };
        });
      },
    };
  })
  .actions((self) => {
    const { httpInstance, load, loaded } = getEnv<BrandStoreEnv>(self);

    const setQueryParams = (filterParams: BrandFilterParams) => {
      applySnapshot(self.filterParams, { ...DefaultBrandFilterParams, ...filterParams });
    };

    const setBrandDetail = (brand: BrandInstance) => {
      self.brandDetail = { ...brand };
    };

    const getBrandsAsync = flow(function* () {
      const loadingId = load('Get Brand Async');
      try {
        const response = yield httpInstance.get(BRAND_API.GET_BRANDS, {
          params: self.getQueryParams,
        });
        applySnapshot(self.brands, response.data);
        applySnapshot(self.pagination, response.metadata.pagination);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getBrandDetailByIdAsync = flow(function* (id: string) {
      const loadingId = load('Get Brand Detail Async');
      try {
        const response = yield httpInstance.get(generatePath(BRAND_API.GET_BRAND, { id: id }));

        response.data.images = [{ dataURL: response.data?.imageUrl as string }];
        self.brandDetail = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const createBrandAsync = flow(function* (data: CreateBrand, cb?: RequestCallback) {
      const loadingId = load('Create Brand Async');
      try {
        yield httpInstance.post(BRAND_API.POST_BRAND, data);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const editBrandAsync = flow(function* (id: string, data: CreateBrand, cb?: RequestCallback) {
      const loadingId = load('Edit Brand Async');
      try {
        yield httpInstance.put(generatePath(BRAND_API.PUT_BRAND, { id: id }), data);

        cb?.success && cb.success();
      } catch (err) {
        console.log(err);
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    const getCategoryOptionsAsync = flow(function* () {
      const loadingId = load('Get Category Async');
      try {
        const response = yield httpInstance.get(CATEGORY_API.GET_CATEGORIES_OPTIONS);
        applySnapshot(self.categoryOptions, response.data);
      } catch (err) {
        console.log(err);
      } finally {
        loaded(loadingId);
      }
    });

    const updateStatus = flow(function* (cb?: RequestCallback) {
      const loadingId = load('Update Status Warehouse');
      const url = generatePath(BRAND_API.PUT_CATEGORY_STATUS, { id: self.brandDetail?.id });

      try {
        yield httpInstance.put(url, {
          status: !self.brandDetail?.enabled,
        });
        cb?.success && cb.success();
      } catch (err) {
        cb?.error && cb.error(err);
      } finally {
        loaded(loadingId);
      }
    });

    return {
      setBrandDetail,
      setQueryParams,
      getBrandsAsync,
      getBrandDetailByIdAsync,
      createBrandAsync,
      editBrandAsync,
      getCategoryOptionsAsync,
      updateStatus,
    };
  });

export default BrandStore;
export type BrandStoreInstance = Instance<typeof BrandStore>;
