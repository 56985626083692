export enum InternalUserRoutes {
  LIST = '/internal-user',
  CREATE = '/internal-user/new',
  EDIT = '/internal-user/:id/edit',
  DETAIL = '/internal-user/:id/details',
}

export enum RoleRoutes {
  LIST = '/role',
  CREATE = '/role/new',
  EDIT = '/role/:id/edit',
  DETAIL = '/role/:id/details',
}
