import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const ProductModel = types.model('Product Model', {
  productId: types.identifier,
  sku: types.string,
  productName: types.string,
  totalQuantily: types.number,
  totalAmount: types.number,
});

export interface ProductInstance extends Instance<typeof ProductModel> {}
export interface ProductInputModel extends SnapshotIn<typeof ProductModel> {}
export interface Product extends SnapshotOut<typeof ProductModel> {}
