import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';
import { MediaIndex } from 'enums';
import { UploadFileModel, UploadInputModel } from 'pages/shared/models';
import { BrandOptionsModel, CategoryOptionsModel } from 'pages/shared/models/common-options';
import { AttributeModel } from './AttributeModel';

export const MediaModel = types.model('Media Model', {
  bucket: types.string,
  extension: types.string,
  fileName: types.string,
  id: types.string,
  mediaIndex: types.enumeration<MediaIndex>(Object.values(MediaIndex)),
  path: types.string,
  title: types.string,
  url: types.string,
  fileId: types.maybeNull(types.string),
});

export const ProductModel = types.model('Product Model', {
  id: types.identifier,
  code: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  vatPercent: types.maybeNull(types.number),
  regularPrice: types.number,
  sellPrice: types.number,
  brandId: types.maybeNull(types.string),
  categoryId: types.maybeNull(types.string),
  category: types.maybeNull(CategoryOptionsModel),
  brand: types.maybeNull(BrandOptionsModel),
  enabled: types.boolean,
  media: types.maybeNull(MediaModel),
  images: types.array(UploadInputModel),
  medias: types.array(UploadFileModel),
  attributes: types.array(AttributeModel),

  createdAt: types.maybeNull(types.string),
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export const DefaultProductValue: Partial<ProductInputModel> = {
  name: '',
  slug: '',
  code: '',
  description: '',
  vatPercent: null,
  regularPrice: NaN,
  sellPrice: NaN,
  brandId: '',
  categoryId: '',
  images: [],
};

export type CreateProduct = Pick<
  ProductInputModel,
  | 'name'
  | 'slug'
  | 'code'
  | 'description'
  | 'vatPercent'
  | 'regularPrice'
  | 'sellPrice'
  | 'brandId'
  | 'categoryId'
  | 'images'
  | 'medias'
  | 'attributes'
>;

export interface ProductInstance extends Instance<typeof ProductModel> {}
export interface ProductInputModel extends SnapshotIn<typeof ProductModel> {}
export interface Product extends SnapshotOut<typeof ProductModel> {}
