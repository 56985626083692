import { types, applySnapshot } from '@vklink/libs-state';
import {
  DefaultPaginationInfo,
  PaginationModel,
  PaginationParamsModel,
  DefaultPaginationParams,
} from 'pages/shared/models/pagination';

export const PaginationStore = types
  .model('Pagination Store', {
    pagination: types.optional(PaginationModel, DefaultPaginationInfo),
    paginationParams: types.optional(PaginationParamsModel, DefaultPaginationParams),
  })
  .actions((self) => {
    const setPaginationParams = (paginationParams: Partial<PaginationParams>) => {
      applySnapshot(self.paginationParams, { ...DefaultPaginationParams, ...paginationParams });
    };

    return {
      setPaginationParams,
    };
  });
