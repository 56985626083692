import React from 'react';
import { Box, Stack } from '@mui/material';

type Props = {
  items: Array<Component>;
};

type Component = {
  title?: string;
  component: React.ReactNode;
};

const PageContent = (props: Props) => {
  return (
    <Stack direction="column">
      {props.items.map((item, index) => (
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
          key={index}
          pb={props.items.length - 1 != index ? 2 : 0}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.common.white,
            }}
          >
            {item.component}
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default PageContent;
