import { SnapshotIn, types } from '@vklink/libs-state';

export const ProductFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
  type: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
});

export interface ProductFilterParams extends SnapshotIn<typeof ProductFilterParamsModel> {}
export const DefaultProductFilterParams: ProductFilterParams = {
  keyword: '',
  type: '',
  status: '',
};
