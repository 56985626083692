import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const PermissionModel = types.model('Permission', {
  group: types.string,
  value: types.identifier,
  name: types.string,
  description: types.string,
});

export interface PermissionType extends Instance<typeof PermissionModel> {}
export interface PermissionModelSnapshotIn extends SnapshotIn<typeof PermissionModel> {}
export interface Permission extends SnapshotOut<typeof PermissionModel> {}
