import { DataChartMonthlyInstance } from 'pages/dashboard/stores/models';

type PaginationProps = {
  totalPages: number;
  totalItems: number;
  pageNumber: number;
  pageSize: number;
};

export const groupItemsWithKeys = (items: any[], keys: string[]) => {
  const helper: any = {};
  const result = items.reduce((acc, item) => {
    const key = keys.map((k) => item[k]).join('-');
    if (!helper[key]) {
      helper[key] = { ...item };
      helper[key].count = 1;
      acc.push(helper[key]);
    } else {
      helper[key].count += 1;
    }
    return acc;
  }, []);

  return result;
};

export const mappingToPaginationDataGrid = (pagination: PaginationProps) => {
  return {
    totalPages: pagination.totalPages,
    totalItems: pagination.totalItems,
    page: pagination.pageNumber,
    limit: pagination.pageSize,
  };
};

export const countTotalInArray = (array: any, key: string) => {
  return array.reduce((a: any, b: any) => a + (b[key] || 0), 0);
};

export const classifyListItemByProperty = <
  Items extends Record<string, any>,
  ItemKey extends keyof Items,
  ItemValue extends Items[ItemKey],
  ReturnKey extends string = Lowercase<ItemValue>
>(
  list: Items[],
  option: {
    propertyName: ItemKey;
    propertyValues: ItemValue[];
  }
): {
  [key in ReturnKey]: Items[];
} => {
  const returnObj = option.propertyValues.reduce((previous, current) => {
    return {
      ...previous,
      [`${current.toLowerCase()}`]: [],
    };
  }, {});

  return list.reduce((previous, current) => {
    const key = `${current[option.propertyName].toLowerCase()}` as ReturnKey;
    const preValues = previous[key] || [];

    preValues.push(current);

    return {
      ...previous,
      [key]: preValues,
    };
  }, returnObj as Record<ReturnKey, Items[]>);
};

export const mapppingDataMonthly = (
  dataChart: DataChartMonthlyInstance,
  optionalChart?: DataChartMonthlyInstance
) => {
  const result = [
    {
      label: 'Jan',
      value: dataChart?.january,
      optionalValue: optionalChart?.january,
    },
    {
      label: 'Feb',
      value: dataChart?.february,
      optionalValue: optionalChart?.february,
    },
    {
      label: 'Mar',
      value: dataChart?.march,
      optionalValue: optionalChart?.march,
    },
    {
      label: 'Apr',
      value: dataChart?.april,
      optionalValue: optionalChart?.april,
    },
    {
      label: 'May',
      value: dataChart?.may,
      optionalValue: optionalChart?.may,
    },
    {
      label: 'June',
      value: dataChart?.june,
      optionalValue: optionalChart?.june,
    },
    {
      label: 'July',
      value: dataChart?.july,
      optionalValue: optionalChart?.july,
    },
    {
      label: 'Aug',
      value: dataChart?.august,
      optionalValue: optionalChart?.august,
    },
    {
      label: 'Sep',
      value: dataChart?.september,
      optionalValue: optionalChart?.september,
    },
    {
      label: 'Oct',
      value: dataChart?.october,
      optionalValue: optionalChart?.october,
    },
    {
      label: 'Nov',
      value: dataChart?.november,
      optionalValue: optionalChart?.november,
    },
    {
      label: 'Dec',
      value: dataChart?.december,
      optionalValue: optionalChart?.december,
    },
  ];

  return result;
};

export const getFullMonthByAlias = (
  alias:
    | 'Jan'
    | 'Feb'
    | 'Mar'
    | 'Apr'
    | 'May'
    | 'Jun'
    | 'Jul'
    | 'Aug'
    | 'Sep'
    | 'Oct'
    | 'Nov'
    | 'Dec'
) => {
  const month = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
  };

  return month[alias];
};
