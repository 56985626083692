import { SnapshotOut, types, Instance, SnapshotIn } from '@vklink/libs-state';
import { OrderDeliveryInformationModel } from './OrderDeliveryInformationModel';
import { OrderItemModel } from './OrderItemModel';
import { OrderPaymentModel } from './OrderPaymentModel';

export const OrderDetailModel = types.model('Order Model', {
  id: types.identifier,
  orderNumber: types.string,
  customerId: types.string,
  customerName: types.string,
  customerPhone: types.maybeNull(types.string),
  sellDate: types.string,
  status: types.string,
  paymentStatus: types.string,
  orderPaymentInfo: types.maybeNull(OrderPaymentModel),
  paymentMethod: types.maybeNull(types.string),
  paymentTime: types.maybeNull(types.string),
  subtotal: types.number,
  totalPrice: types.number,
  deliveryFee: types.number,
  totalDiscount: types.maybeNull(types.number),
  couponCode: types.maybeNull(types.string),
  couponValue: types.string,
  pointDiscount: types.maybeNull(types.number),
  rebaseOrderNumber: types.maybeNull(types.string),
  trackAndTrace: types.maybeNull(types.string),
  createdAt: types.string,
  createdById: types.string,
  createdBy: types.maybeNull(types.string),
  orderItems: types.array(OrderItemModel),
  orderDeliveryInfo: types.maybeNull(OrderDeliveryInformationModel),
});

export interface OrderDetail extends SnapshotOut<typeof OrderDetailModel> {}
export interface OrderDetailInputModel extends SnapshotIn<typeof OrderDetailModel> {}
export interface OrderDetailInstance extends Instance<typeof OrderDetailModel> {}
