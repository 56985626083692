import { SnapshotIn, types } from '@vklink/libs-state';

export const CouponFilterParamsModel = types.model({
  keyword: types.optional(types.string, ''),
});

export interface CouponFilterParams extends SnapshotIn<typeof CouponFilterParamsModel> {}
export const DefaultCouponFilterParams: CouponFilterParams = {
  keyword: '',
};
