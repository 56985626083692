import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const CouponModel = types.model('Coupon Model', {
  id: types.identifier,
  name: types.string,
  code: types.string,
  type: types.string,
  value: types.number,
  enabled: types.boolean,
  description: types.maybeNull(types.string),
  maximumQuantity: types.maybeNull(types.number),
  quantityUsed: types.maybeNull(types.number),
  startDate: types.string,
  endDate: types.string,
  createdAt: types.string,
  createdById: types.string,
  createdBy: types.string,
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  updatedById: types.maybeNull(types.string),
});

export const DefaultCouponValue: CreateCoupon = {
  name: '',
  type: '',
  value: NaN,
  description: '',
  maximumQuantity: NaN,
  startDate: '',
  endDate: '',
  code: '',
};

export type CreateCoupon = Pick<
  CouponInputModel,
  'name' | 'code' | 'type' | 'value' | 'description' | 'maximumQuantity' | 'endDate' | 'startDate'
>;

export interface CouponInstance extends Instance<typeof CouponModel> {}
export interface CouponInputModel extends SnapshotIn<typeof CouponModel> {}
export interface Coupon extends SnapshotOut<typeof CouponModel> {}
