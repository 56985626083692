import { PaymentStatus } from 'enums';

export const PaymentStatusOptions: SelectOption[] = [
  {
    label: 'Paid',
    value: PaymentStatus.PAID,
  },
  {
    label: 'Unpaid',
    value: PaymentStatus.UN_PAID,
  },
  {
    label: 'Waiting',
    value: PaymentStatus.WAITING,
  },
];
