import { types, Instance, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

const ParentCategoryModel = types.model({
  id: types.identifier,
  name: types.string,
});

export const CategoryModel = types.model('Category Model', {
  id: types.identifier,
  name: types.string,
  parentId: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  slug: types.string,
  enabled: types.boolean,
  parent: types.maybeNull(ParentCategoryModel),
  createdAt: types.string,
  createdBy: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
});

export const DefaultCategoryValue: Partial<CategoryInputModel> = {
  name: '',
  parentId: '',
  description: '',
  slug: '',
};

export type CreateCategory = Pick<CategoryInputModel, 'name' | 'parentId' | 'description' | 'slug'>;

export interface CategoryInstance extends Instance<typeof CategoryModel> {}
export interface CategoryInputModel extends SnapshotIn<typeof CategoryModel> {}
export interface Category extends SnapshotOut<typeof CategoryModel> {}
