import { types, SnapshotIn, SnapshotOut } from '@vklink/libs-state';

export const AttributeModel = types.model('Attribute Model', {
  id: types.string,
  name: types.string,
  attributeType: types.string,
  values: types.array(types.string),
  attributeValues: types.array(
    types.model({
      id: types.maybeNull(types.string),
      value: types.string,
    })
  ),
});

export const DefaultAttributeValue: Partial<AttributeInputModel> = {
  name: '',
  attributeType: '',
  values: [],
};

export interface AttributeInputModel extends SnapshotIn<typeof AttributeModel> {}
export interface Attribute extends SnapshotOut<typeof AttributeModel> {}
