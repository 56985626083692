import { createStoreContext } from '@vklink/libs-state';
import { CustomerStoreInstance } from './CustomerStore';

const [CustomerStoreProvider, useCustomerStore] = createStoreContext<CustomerStoreInstance>();

export { CustomerStoreProvider, useCustomerStore };

export {
  default as CustomerStore,
  type CustomerStoreEnv,
  type CustomerStoreInstance,
} from './CustomerStore';
